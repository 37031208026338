import { Link } from "react-router-dom";
import Icon from "./Icon";
import * as ConstantVar from "../../constants_var";
 

const BigBlogCard = (props) => {
  const style = {
    backgroundImage:
      `url(${ConstantVar.API_BASE_URL}/static/blog/` + props.post._id + "/featured.png)",
  };
  return (
    <>
      <div className="big-blog-card">
        <div className="big-blog-card-image" style={style}></div>
        <div className="d-flex flex-row mt-0 mt-md-4 mb-4">
          <div className="col-12 col-lg-7 flex-wrap post-title">
            <Link to={"/blog/posts/" + props.post.url} className="text-dark">
              {props.post.title}
            </Link>
            <div className="short-text">{props.post.caption}</div>
          </div>
          <div className="col-12 col-lg-5 d-none d-lg-flex flex-grow-1 justify-content-end">
            <Link to={"/blog/posts/" + props.post.url} className="learn-more">
              Learn more{" "}
              <Icon
                height="16px"
                width="16px"
                icon="/assets/images/icons/arrow-right.svg"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BigBlogCard;
