import { Component } from "react";
import Footer from "../../components/shared/Footer";
import { app } from "./firebaseConfig";
import { getDatabase,get,child, ref, set } from "firebase/database";

export default class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      hasJoined: false,
      isLoading: true,
      showError: false,
      isRegistered: false,
      isStarted: false,
      isDisabled: false,
      gameOver: false,
      joinedGame: false,
      questionCount: 0,
      questions: this.questions,
      isClicked: false,
      scoredPoint: 0,
      countDown: 45,

      timer: 0, // Countdown time in seconds
      counting: false, // To control the countdown
    };

    this.interval = null; // Interval reference
    this.handleStartGame = this.handleStartGame.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
  }

  // useComponent didmount to check if user already played
  // at start game, confirm the user has not played previously using the api;

 
 

  handleStartGame = () => {
    this.setState({ isStarted: true }, () => {
      this.startCountdown();
    });
  };

  handleAnswer = (point) => {
    this.setState(
      {
        isClicked: true,
        isDisabled: true,
        scoredPoint: (this.state.scoredPoint += point),
      },
      () => {
        if (this.state.questions.length != this.state.questionCount + 1) {
          this.handleNext();
        } else {
          this.handleSubmit();
        }
      }
    );
  };

  handleSubmit = () => {
    const database = getDatabase(app);
    // app.d(ref(database,'/users').set({
    set(ref(database, `/scores/${this.state.phone}`), {
      phone: this.state.phone,
      name: this.state.name,
      email: this.state.email,
      score: this.state.scoredPoint,
      // Other data fields
    }).then(() => {
      console.log("Data submitted successfully!");
      this.setState({ gameOver: true },() =>{
});
});
    // this.setState({ gameOver: true });
  };

  handleNext = () => {
    // e.preventDefault()
    this.setState({
      questionCount: this.state.questionCount + 1,
      isClicked: false,
      isDisabled: false,
    });
  };

  handlePrevious = (e) => {
    e.preventDefault();
    this.setState({ questionCount: this.state.questionCount - 1 });
  };

  questions = [
    { question: "What is a bitcoin", answer: "Cryptocurrency", options: [
        {point:0,option:"Digital money",isAnswer:false},
        {point:0,option:"Cashless money",isAnswer:false},
        {point:0,option:"Digital fiat",isAnswer:false},
        {point:10,option:"Cryptocurrency",isAnswer:true}
    ] },
    { question: "What network controls bitcoin", answer: "Blockchain", options: [
        {point:0,option:"Ledger",isAnswer:false},
        {point:0,option:"Central bank",isAnswer:false},
        {point:10,option:"Blockchain",isAnswer:true},
        {point:0,option:"Tron",isAnswer:false}
    ] },
    { question: "Who is the founder of Ethereum?", answer: "Vitalik", options: [
        
        {point:10,option:"Vitalik",isAnswer:true},
        {point:0,option:"Satoshi",isAnswer:false},
        {point:0,option:"Saylor",isAnswer:false},
        {point:0,option:"Elon",isAnswer:false}
    ] },
    { question: "Which of the following is a meme coin", answer: "C", options: [

        {point:0,option:"Ethereum",isAnswer:false},
        {point:0,option:"XRP",isAnswer:false},
        {point:0,option:"Bitcoin",isAnswer:false},
        {point:10,option:"Doge",isAnswer:true}
    ] },
    { question: "What is the total supply of bitcoin?", answer: "22 million", options: [
        
        {point:0,option:"19 million",isAnswer:false},
        {point:0,option:"20 million",isAnswer:false},
        {point:0,option:"21 million",isAnswer:false},
        {point:10,option:"22 million",isAnswer:true}
    ] },
  
    { question: "A tool for storing cryptocurrency offline from hacks is called?", answer: "Cold wallet", options: [
        {point:0,option:"Wallet",isAnswer:false},
        {point:0,option:"Hot wallet",isAnswer:false},
        {point:10,option:"Cold wallet",isAnswer:true},
        {point:0,option:"Software wallet",isAnswer:false}
    ] },
    { question: "The idea behind crypto is", answer: "Decentralization", options: [
        {point:0,option:"Free money",isAnswer:false},
        {point:10,option:"Decentralization",isAnswer:true},
        {point:0,option:"Pump and dump",isAnswer:false},
        {point:0,option:"P2P",isAnswer:false}
    ] },
    { question: "A coin that has its value pegged to a fiat money is called?", answer: "Stable coin", options: [
        
        {point:0,option:"flash coin",isAnswer:false},
        {point:10,option:"Stable coin",isAnswer:true},
        {point:0,option:"Crypto coin",isAnswer:false},
        {point:0,option:" Kucoin",isAnswer:false}
    ] },
    { question: "Which of the following is a central bank digital currency (CBDC)", answer: "e-Naira", options: [

        {point:10,option:"e-Naira",isAnswer:true},
        {point:0,option:"USDT",isAnswer:false},
        {point:0,option:"USDC",isAnswer:false},
        {point:0,option:"BUSD",isAnswer:false}
    ] },
    { question: "Bitcoin was founded in what year?", answer: "2009", options: [
        
        {point:0,option:"2002",isAnswer:false},
        {point:0,option:"2005",isAnswer:false},
        {point:0,option:"2010",isAnswer:false},
        {point:10,option:"2009",isAnswer:true}
    ] },
  ];


//   questions = [
//     {
//       question: " What is a bitcoin",
//       answer: "A",
//       options: [
//           { point: 0, option: "Digital money", isAnswer: false },
//           { point: 0, option: "Cashless money", isAnswer: false },
//           { point: 0, option: "Digital fiat", isAnswer: false },
//           { point: 10, option: "Cryptocurrency", isAnswer: true },
//       ],
//     },
//     {
//       question: "What network controls bitcoin",
//       answer: "D",
//       options: [
//         { point: 0, option: " Ledger ", isAnswer: false },
//         { point: 0, option: "Central bank ", isAnswer: false },
//         { point: 10, option: "Blockchain", isAnswer: true },
//         { point: 0, option: "Tron", isAnswer: false },
//       ],
//     },
//     {
//       question: "HOW is boti",
//       answer: "C",
//       options: [
//         { point: 0, option: "A", isAnswer: false },
//         { point: 0, option: "B", isAnswer: false },
//         { point: 1, option: "C", isAnswer: true },
//         { point: 0, option: "D", isAnswer: false },
//       ],
//     },
//     {
//       question: "WHY is boti boti botibotibotiboti ,WHY is boti boti botibotibotiboti   boti  boti, WHY is boti boti botibotibotiboti   boti, WHY is boti boti botibotibotiboti   boti",
//       answer: "C",
//       options: [
//         { point: 0, option: "A", isAnswer: false },
//         { point: 0, option: "B", isAnswer: false },
//         { point: 1, option: "C", isAnswer: true },
//         { point: 0, option: "D", isAnswer: false },
//       ],
//     },
//     {
//       question: "WHAT is boti",
//       answer: "B",
//       options: [
//         { point: 0, option: "A", isAnswer: false },
//         { point: 1, option: "B", isAnswer: true },
//         { point: 0, option: "C", isAnswer: false },
//         { point: 0, option: "D", isAnswer: false },
//       ],
//     },
//   ];

  // handleStartGame = () => {
  //    this.setState({ start: !this.state.start });
  // };

  register = (e) => {
    e.preventDefault();

    const { email, phone, name } = this.state;
    if (email.trim() === "") {
        // If the field is empty, show error
        this.setState({ showError: true });
        return; // Don't proceed with submission
    }
    if (phone.trim() === "") {
        // If the field is empty, show error
        this.setState({ showError: true });
        return; // Don't proceed with submission
    }
    if (name.trim() === "") {
        // If the field is empty, show error
        this.setState({ showError: true });
        return; // Don't proceed with submission
    }

    this.checkDataExistsInDatabase( phone)
  .then((exists) => {
    if (exists) {
      console.log('Data exists in Firebase Database');
      
      const dbRef = ref(getDatabase());
    return get(child(dbRef, `scores/${phone}`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
      console.log(snapshot.val().score);
      this.setState({gameOver:true,scoredPoint:snapshot.val().score})
      
    } else {
      console.log("No data available");
    }
    })

    } else { 
        const database = getDatabase(app);
        console.log("got successfully!");

        set(ref(database, `/users/${this.state.phone}`), {
          email: this.state.email,
          phone: this.state.phone,
          name: this.state.name,
          // Other data fields
        }).then(() => {
          console.log("Data saved successfully!");
          this.setState({ isRegistered: true },() =>{
    });
        });
      console.log('Data does not exist in Firebase Database');
    }
  });
   
    
  };

  joinGame = (e) => {
    e.preventDefault();
    this.setState({ joinedGame: true });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value ,showError:false});
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startCountdown = () => {
    this.setState({
      timer: this.state.countDown, // Set the timer to 60 seconds
      counting: true, // Start the countdown
    });

    this.interval = setInterval(this.updateTimer, 1000);
  };

  updateTimer = () => {
    if (this.state.timer > 0) {
      this.setState((prevState) => ({
        timer: prevState.timer - 1,
      }));
    } else {
      clearInterval(this.interval);
      this.setState(
        {
          counting: false, // Countdown finished, set counting to false
        },
        () => {
          this.handleSubmit();
        }
      );
    }
  };

  checkDataExistsInDatabase = (phone) => {
    // const dbRef = ref(getDatabase(app));
    const database = getDatabase(app);

    const dbRef = ref(database);

    return get(child(dbRef, `users/${phone}`)).then((snapshot) => {
      console.log("snapshot:: ",snapshot)
  if (snapshot.exists()) {
    console.log(snapshot.val());
   return true
    
  } else {
    console.log("No data available");
    return false
  }
}).catch((error) => {
  console.log("error available:: ",error);

  // console.error(error);
  return false

});

  };
  

  render() {
    const { timer, counting } = this.state;

    const disabledStyle = {
      pointerEvents: this.isDisabled ? "none" : "auto",
    };
    return (
      <>
        {/* <Header /> */}

        <div id="section-5" className="page-content ">
          <div className="container about-us pt-4">
            {!this.state.gameOver ? (
              <>
                <div className="headline col-12 col-md-8 mx-auto">
                  {!this.state.isRegistered 
                    ? "Join to Play the Game"
                    : "Play Game"}
                </div>

                <>
                  <h3 className="text-center"></h3>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="">
                        <div
                          className="nav flex-column nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          {" "}
                          {!this.state.isRegistered && (
                            <a
                              className="nav-link backgrd-color card profile-nav"
                              id="registeration-tab"
                              data-toggle="pill"
                              href="#registeration"
                              role="tab"
                              aria-controls="registeration"
                              aria-selected="true"
                            >
                              Registration
                            </a>
                          )}
                          {this.state.isRegistered && (
                            <a
                              className="nav-link backgrd-color card profile-nav"
                              id="game-tab"
                              data-toggle="pill"
                              href="#game"
                              role="tab"
                              aria-controls="game"
                              aria-selected="true"
                            >
                              Game
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-9">
                      {!this.state.isRegistered ? (
                        <div
                          className="tab-pane fade show active"
                          id="registeration"
                          role="tabpanel"
                          aria-labelledby="registeration_tab"
                        >
                          <form
                            autoComplete="off"
                            className="d-flex flex-column m-2"
                          >
                            {/* <form  autoComplete="off" className="d-flex align-items-center justify-content-between" > */}

                            <div className="form-group p-2">
                              {/* <label
                                htmlFor="formGroupExampleInput"
                                className="profile-input-label text-muted"
                                >
                                Your Name
                                </label> */}

                              <input
                                type="text"
                                name="name"
                                className="form-control text-primary"
                                id="formGroupExampleInput2"
                                placeholder="Your name"
                                required={true}
                                onChange={this.handleChange}
                              />
                              
                            </div>
                              {this.state.showError && (
                                <span style={{ color: "red" }}>
                                  This field is required
                                </span>
                              )}

                            <div className="form-group  p-2">
                              {/* <label
                                htmlFor="formGroupExampleInput"
                                className="profile-input-label text-muted"
                                >
                                Your Email
                                </label> */}
                              <input
                                type="text"
                                name="email"
                                inputMode="email"
                                className="form-control text-primary"
                                id="formGroupExampleInput2"
                                placeholder="Your email"
                                required={true}
                                onChange={this.handleChange}
                              />
                            </div>
                              {this.state.showError && (
                                <span style={{ color: "red" }}>
                                  This field is required
                                </span>
                              )}
                            <div className="form-group  p-2">
                              <input
                                type="text"
                                name="phone"
                                required={true}
                                inputMode="tel"
                                className="form-control text-primary"
                                id="formGroupExampleInput2"
                                placeholder="Your phone_number"
                                onChange={this.handleChange}
                              />
                            </div>
                              {this.state.showError && (
                                <span style={{ color: "red" }}>
                                  This field is required
                                </span>
                              )}
                            <div className="text-center">
                              <button
                                // disabled={this.state.isLoading}
                                type="submit"
                                className="btn btn-primary text-bold text-dark   float-center bg-light"
                                onClick={this.register}
                              >
                                Join the Game
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <>
                          {!this.state.isStarted ? (
                            <div className="text-center ">
                              <h4
                                onClick={this.handleStartGame}
                                className="text-dark m-4 btn bg-light"
                              >
                                Start Game
                              </h4>
                            </div>
                          ) : (
                            <div
                              className="tab-pane fade show active"
                              id="game"
                              role="tabpanel"
                              aria-labelledby="game_tab"
                            >
                              <div className="d-flex justify-content-around pt-2 mr-2 ">
                                {/* <h3> QUIZ {" "}</h3> */}
                                {counting && (
                                  <h3 className="ml-4 p-4 rounded-circle headline bg-danger text-light">
                                    {timer}
                                  </h3>
                                )}
                              </div>
                              <div className="bg-primary  p-3 m-4">
                                {/* <div className="bg-success primary-link-btn p-3 m-4"> */}
                                <h5>
                                  {
                                    this.questions[this.state.questionCount]
                                      .question
                                  }{" "}
                                </h5>
                                <div className="d-flex flex-column ">
                                  {this.questions[
                                    this.state.questionCount
                                  ].options.map((doc, index) => (
                                    <h5
                                      style={disabledStyle}
                                      key={index}
                                      value={doc.point}
                                      onClick={() =>
                                        this.handleAnswer(doc.point)
                                      }
                                      className={[
                                        this.state.isClicked
                                          ? "btn btn-normal"
                                          : "btn btn-danger",
                                        "p-4 bg-light m-4",
                                      ]}
                                    >
                                      {doc.option}
                                    </h5>
                                  ))}
                                </div>
                                {/* <div className="d-flex justify-content-between align-items-center">
                                        {this.state.questionCount > 0 && 
                                        <h5
                                        onClick={this.handlePrevious}
                                        
                                        >PREVIOUS</h5>}{" "}
                                        { this.questions.length >this.state.questionCount ||this.state.questions.length != this.state.questionCount+1   &&  
                                        <h5
                                        onClick={this.handleNext}
                                        >NEXT</h5>
                                        }
                                    </div> */}
                                {this.state.questions.length ==
                                  this.state.questionCount + 1 && (
                                  <h5
                                    className="text-center"
                                    onClick={this.handleSubmit}
                                  >
                                    SUBMIT
                                  </h5>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              </>
            ) : (
              <div >
              <p className="headline bg-light col-9 col-md-8 mx-auto text-danger">
                Game Over
                </p>
                <div className=" headline text-light">
                  <h1>You Scored {this.state.scoredPoint} points</h1>
                </div>
                <div className="d-flex flex-column ">
                  {this.state.questions.map((question, index) => (
                    <div className="d-flex flex-column " key={index}>
                     <p className="bg-light text-dark"> 
                        <strong className="m-2 p-2 ">{index+1}:</strong>{" "}
                      <span>{question.question}</span>
                    </p>
                      <div className="d-flex flex-column ">
                        {question.options.map((doc, p_index) => (
                          <h5
                            style={disabledStyle}
                            key={p_index}
                            value={doc.point}
                            className={`${
                              doc.isAnswer ? "bg bg-success" : "bg bg"
                            } text-light`}
                          >
                            {doc.option}
                          </h5>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}