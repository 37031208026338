import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { Component } from "react";
import { Helmet } from 'react-helmet'

export default class Terms extends Component {
  render() {
    return (
      <>
        <Helmet>
          {/* <html lang="en" />   */}
          <title>Terms</title>
          <link rel="canonical" href="https://kochure.com/terms" />
          <meta 
            name="keywords"
            content="Bitcoin trading platform for newbies"
          />
        </Helmet>
     

        <Header />
        <div className="page-content">
          <div className="page">
            <div className="header">
              <div className="col-12 col-md-10 mx-auto">
                <h1 className="headline">Terms of Service</h1>
                <div className="my-3">Document effective October 1 , 2021</div>
              </div>
            </div>
            <div className="my-5 container has-animation">
              <div className="col-12 col-md-10 mx-auto">
                <p>
                  Welcome to Kochure (the “Platform”), an impact-driven platform which
                  serves as a medium of exchange of digital assets and other services.
                  This Platform is brought to you by Kochure Technologies Limited
                  (“Kochure”, “we”, “us”, or “our”).
                </p>
                <div className="h2">1. Consent</div>
                <p>
                  This Terms of Use constitutes a legally binding contract between you,
                  (whether as a guest or registered user) and Kochure in your access and
                  use of the Platform as well as its content, services and functionality
                  made available on or through the Platform (collectively referred to as
                  “the Services”). We therefore kindly request that you carefully read
                  through these Terms and Conditions of Use (the “Terms of Use” or “User
                  Agreement”) before using the Platform and click on the Agree icon if you
                  consent and agree to be bound by (1) this Terms of Use and (2) the
                  Privacy Policy (“the Policy”).
                </p>
                <p>
                  By using the Platform, you accept the terms and conditions contained on
                  this Platform, which shall govern your use of this Platform, including
                  all pages or links on the Platform. If you do not agree to these Terms
                  of Use or the Privacy Policy, please do not use the Platform and/or the
                  Services and exit immediately. In addition, if you do not agree to these
                  Terms of Use or the Policy, you agree that your sole and exclusive
                  remedy is to discontinue using this Platform. That notwithstanding, if
                  you proceed to browsing, accessing, or otherwise using the Services in
                  any manner, it will be deemed that you have accepted, without limitation
                  or qualification, both the Terms of Use and the Privacy Policy for the
                  Platform (the “Policy”).
                </p>
                <div className="h2">2. Review of Terms of Use</div>
                <p>
                  We may, at our sole discretion, review, revise and/or update this Terms
                  of Use and the Platform at any time. We therefore advise that you read
                  through this Terms of Use periodically. You further agree that we will
                  have no further obligation to notify you of any modifications and such
                  updates or modifications shall become effective immediately upon the
                  posting thereof or on any specified date. The most current version of
                  the Terms of Use can be accessed at any time by selecting Terms of Use
                  link on the Platform.
                </p>
                <div className="h2">3. Age</div>
                <p>
                  You must be at least 18 (eighteen) years old to use our Platform or any
                  of our Services; by using our Platform or agreeing to these terms and
                  conditions, you represent and warrant to us that you are at least 18
                  years of age. Individuals under the age of 18, or applicable age of
                  maturity, may utilize our Services only with involvement of a parent or
                  legal guardian, under such person’s account.
                </p>
                <div className="h2">4. Security of Password</div>
                <p>
                  To fully enjoy our Services, you may be required to create an account
                  and in which case you will be given (or you may provide) a username and
                  password. Certain areas of our Services are only accessible with the use
                  of username and passwords (“User Restricted Areas”). Please note that
                  you are fully and solely responsible for any and all use of the Services
                  and such, you are required to keep your password secret at all times. Do
                  not share your password with any third party or allow another person to
                  access the User Restricted Areas using your password. Kindly notify us
                  immediately if you have any reason to believe that your username or
                  password has been lost or compromised or misused in any way. Also
                  immediately report any unauthorized or suspicious activity in your
                  account.
                </p>
                <div className="h2">
                  5. User Information/Know- Your-Customer (Kyc) Requirements
                </div>
                <p>
                  5.1 In creating an Account and registering for the Services on our
                  Platform, we may require you to provide and/or confirm information and
                  documentation that will allow us to identify you, such as: A copy of
                  your government-issued photo ID, such as a passport or driver’s license;
                  A copy of a utility bill, bank statement, affidavit, or other bill,
                  dated within three months of our request, with your name and residential
                  street address on it; and Such other information and documentation that
                  we may require from time to time.
                </p>
                <p>
                  5.2 You hereby authorize us to, directly or through a third-party,
                  obtain, verify, and record information and documentation that helps us
                  verify your identity and Bank Account information.
                </p>
                <div className="h2">6 Kochure Services</div>
                <p>a. General Services</p>
                <p>
                  Kochure as a medium of exchange offers a dynamic experience which
                  encompasses several functionalities such as; 2FA login authentication,
                  existence of Know-Your-Customer mechanisms, ability to trade the
                  currencies supported by the exchange and sell them, availability of
                  market depth chart, ability to show current users portfolio, display of
                  users trade history, account overview, wallet funds deposits and
                  withdrawals, languages options, purchase with credit cards, ability to
                  set withdrawal limits, frictionless transaction environment,
                  Person-to-Person (P2P) Exchange services, Anti-Market Manipulation and
                  Anti-Money Laundering checks, risk-prevention systems, High Performance
                  Matching Engine, Customer Support Desk etc.
                </p>
                <p>b. Wallet Services</p>
                <p>
                  i. Permitted Use: Our Hosted Digital Currency Wallet services allows you
                  send supported Digital Currency to, and request, receive, and store
                  supported Digital Currency from, third parties pursuant to instructions
                  you provide through the Kochure Site (each such transaction could be
                  termed a "Digital Currency Transaction").
                </p>
                <p>
                  ii. Transaction Operations: Kochure reserves the right to refuse to
                  process or to cancel any pending Digital Currency Transaction as
                  required by law or in response to a subpoena, court order, or other
                  binding government order or to enforce transaction limits. The hosted
                  Digital Currency Wallet services are available only in connection with
                  those Digital Currencies that Kochure, in its sole discretion, decides
                  to support and it therefore denounces liability for trading in
                  currencies not supported by Kochure.
                </p>
                <p>
                  iii. Digital Currency Transactions: Kochure processes supported Digital
                  Currency according to the instructions received from its users and we do
                  not guarantee the identity of any user, receiver, requester, or other
                  party. You should verify all transaction information prior to submitting
                  instructions to Kochure. Using Kochure to initiate a Digital Currency
                  Transaction maybe attract transaction fees to be charged at the
                  discretion of Kochure. Kochure will endeavour to notify you of the
                  network fee before or at the time you authorise/process the transaction
                  and the transaction fees charged shall form part of the transaction
                  amount being processed at such material point in time.
                </p>
                <p>
                  iv. Digital Currency Storage & Transmission Delays: You agree that
                  Kochure while carrying out its normal operational functions in
                  facilitating the initiation or processing of currency transactions, may
                  experience delays up to 48-72hours or in severe circumstances, could
                  extend for a longer period. Kochure disclaims any liability that could
                  arise as a result of such delays.
                </p>
                <p>
                  v. Third Party Payments: Kochure also disclaims control over, or
                  liability for, the delivery, quality, safety, legality or any other
                  aspect of any goods or services (excluding Digital Currency bought or
                  sold via the Kochure site) that you may purchase or sell to or from a
                  third party (including other users of Kochure Services). Regardless of
                  the above, you are expected to conduct adequate due diligence to make an
                  informed decision.
                </p>
                <p>
                  vi. Advanced Protocols: Kochure is not responsible for operation of any
                  underlying protocols and that Kochure makes no guarantee of their
                  functionality, security, or availability.
                </p>
                <p>
                  vii. Fraud Prevention: Kochure reserves the right to refuse to process,
                  or to cancel or reverse, any purchases or sales of Digital Currency in
                  its sole discretion, even after funds have been debited from your
                  account(s), if Kochure suspects the transaction involves (or has a high
                  risk of involvement in) money laundering, terrorist financing, fraud, or
                  any other type of financial crime; in response to a subpoena, court
                  order, or other government order; if Kochure reasonably suspects that
                  the transaction is erroneous; or if Kochure suspects the transaction
                  relates to Prohibited Use or a Prohibited Business. In such instances,
                  Kochure will reverse the transaction and we are under no obligation to
                  allow you to reinstate a purchase or sale order at the same price or on
                  the same terms as the cancelled transaction.
                </p>
                <p>
                  viii. Unclaimed Property. If Kochure is holding funds in your account,
                  and Kochure is unable to contact you and has no record of your use of
                  the Services for several years, applicable law may require Kochure to
                  report these funds as unclaimed property to the applicable jurisdiction.
                  If this occurs, Kochure will try to locate you at the address or contact
                  information available in our records, but if Kochure is unable to locate
                  you, it may be required to deliver any such funds to the applicable or
                  jurisdiction as unclaimed property. Kochure reserves the right to deduct
                  a dormancy fee or other administrative charges from such unclaimed
                  funds, as permitted by applicable law.
                </p>
                <p>
                  ix. Taxes: It is your absolute responsibility to determine whether, and
                  to what extent, any taxes apply to any transactions you conduct through
                  the Kochure Services, and to withhold, collect, report and remit the
                  correct amounts of taxes to the appropriate tax authorities. Your
                  transaction history is available through your Kochure Account.
                </p>
                <div className="h2">7 Available Content and Use</div>
                <p>a. Content Description:</p>
                <p>
                  The Platform contains variety of contents, including but not limited to
                  text, data, files, documents, software, scripts, layout, design,
                  function, aesthetics, graphics, images, audio, videos, audiovisual
                  combinations, interactive features and any other materials that you may
                  view, access or download (but only as expressly permitted in paragraph C
                  below) on the Platform.
                </p>
                <p>b. Proprietary Rights</p>
                <p>
                  All the contents of the Platform, whether publicly posted or privately
                  transmitted, as well as all derivative works thereof, are owned by
                  Kochure, its affiliates and where applicable, to third parties who have
                  licensed such rights to Kochure (“Licensors”). Hence, the Platform is
                  protected by copyright, trademark, and other applicable intellectual
                  property rights/laws. Except as specifically permitted herein, you shall
                  not copy, reproduce, republish, upload, post, transmit, modify or
                  distribute the Platform or any part of it in any way, including by
                  e-mail or other electronic means, without the prior consent of Kochure
                  or its Licensors.
                </p>
                <p>c. Restrictions on use of the Platform</p>
                <p>
                  Please note that the contents of this Platform are solely for your
                  information and personal use, as intended through the provided
                  functionality of the Services and permitted under this User Agreement.
                  As such, you:
                </p>
                <p>
                  i. are prohibited from modifying or using the Content on any web
                  Platform or networked computer environment or using the Content for any
                  purpose other than personal, non-commercial use, without the consent of
                  Kochure or its licensors first had and obtained, where applicable. Such
                  use or modification is a violation of the copyright, trademark, and
                  other proprietary rights in the Content.
                </p>
                <p>
                  ii. shall not use our Platform in any way or take any action that causes
                  or may cause damage to the Platform or impairment of the performance,
                  availability or accessibility of the Platform;
                </p>
                <p>
                  iii. shall not use our Platform in any way that is unlawful, illegal,
                  fraudulent or harmful, or in connection with any unlawful, illegal,
                  fraudulent or harmful purpose or activity or in any way that breaches
                  any applicable law or regulations;
                </p>
                <p>
                  iv. shall not to circumvent, disable or otherwise interfere with
                  security-related features of the Services; including security features
                  that prevent or restrict the use or copying of any content.
                </p>
                <p>
                  v. shall not use data collected from our Platform for any direct
                  marketing activity (including without limitation email marketing, SMS
                  marketing, telemarketing or direct mailing).
                </p>
                <p>
                  vi. shall not to alter, remove, or falsify any attributions or other
                  proprietary designations of origin or source of any Content appearing on
                  the Platform.
                </p>
                <p>
                  vii. Shall not use the Platform in any way to create liability, or in
                  any way that causes us to lose the services of our Internet Service
                  Providers or other suppliers.
                </p>
                <p>
                  viii. shall not download any content on the Platform unless you see a
                  “download” or similar link displayed by us on the Platform for such
                  content.
                </p>
                <p>You further agree that:</p>
                <p>
                  i. you are responsible for your own conduct while using the Platform or
                  Services and for any consequences thereof;
                </p>
                <p>
                  ii. You shall use this Platform and all Services on the Platform only
                  for purposes that are legal, proper and in accordance with this Terms of
                  Use, the Privacy Policy, and any applicable law, rules or regulations,
                  any consumer protection, unfair competition, and anti-discrimination
                  laws or regulations and any applicable foreign laws).
                </p>
                <p>
                  iii. You are solely responsible for your interactions with other users
                  of the Platform (“Users”) both within the Platform and outside of the
                  Platform. Kochure expressly disclaims any responsibility for
                  interactions, loss or liabilities between users or between you and any
                  third party outside of the Platform.
                </p>
                <p>d. Disclaimer</p>
                <p>
                  i. Your use of the Services is at your sole risk and the Platform is
                  provided to you on an ‘AS IS’ and ‘AS AVAILABLE’ basis. You therefore
                  agree to evaluate, seek independent advice and bear all risks associated
                  with the use of the Platform.
                </p>
                <p>
                  Nothing on the Platform should be deemed to constitute a recommendation
                  to purchase, sell or hold, or otherwise to constitute an advice
                  regarding, any investment, secured or otherwise.
                </p>
                <p>
                  ii. We will not be liable for any damages (including, without
                  limitation, damages for any consequential loss or loss of business
                  opportunities or projects, or loss of profits) howsoever arising and
                  whether in contract, tort or otherwise from the use of or inability to
                  use our Platform, or any of its contents and materials, or from any
                  action or omission taken as a result of using the Platform or any such
                  contents. In any event, our liability for any and all proven damages or
                  losses shall not in any circumstances exceed the amount paid by you, if
                  any, for accessing this Platform.
                </p>
                <p>
                  iii. All liability is excluded to the fullest extent permitted by
                  applicable law including any implied terms as the content of this
                  Platform “as is” without warranties of any kind. We reserve the right to
                  change all the contents of this Platform at any time without notice to
                  you.
                </p>
                <div className="h2">8. Confidentiality</div>
                <p>
                  a. You undertake that all communication, content, intellectual property
                  or other information, and materials on the Platform, either marked
                  ‘confidential’ or is by its nature intended to be for your knowledge
                  alone, shall be kept confidential unless or until you can reasonably
                  demonstrate that such communication, information and material is, or
                  part of it is, in the public domain through no fault of yours.
                  Furthermore, any communication, content, intellectual property or other
                  information, and materials you obtain in terms of or arising from the
                  use of this Platform shall be treated as confidential and shall not be
                  divulged or permitted to be divulged to third parties, without our prior
                  written consent.
                </p>
                <p>
                  b. Please note that all obligations relating to Confidential Information
                  under this Terms of Use will continue after termination of the Terms of
                  Use and termination of access rights hereunder.
                </p>
                <div className="h2">9. Use of suggestions</div>
                <p>
                  You acknowledge and agree that any questions, comments, suggestions,
                  ideas, feedback, or other information provided by you to Kochure
                  (collectively, “Feedback”) are not confidential and you hereby grant to
                  Kochure a worldwide, perpetual, irrevocable, royalty-free license to
                  reproduce, display, perform, distribute, publish, modify, edit or
                  otherwise use such Feedback as it deems appropriate, for any and all
                  commercial or non-commercial purposes, in its sole discretion.
                </p>
                <div className="h2">10. Undertakings</div>
                <p>You hereby agree as follows:</p>
                <p>
                  a. Undertakingsnot to access or use the Services in an unlawful way or
                  for an unlawful or illegitimate purpose, including without limitation
                  any violation of any criminal or anti-money laundering laws of the
                  Federal Republic of Nigeria or any other jurisdiction where you are
                  resident;
                </p>
                <p>
                  b. not to post, publish or transmit on the Platform (a) any message or
                  information under a false name; (b) information that is unlawful,
                  malicious, libelous, defamatory, obscene, fraudulent, predatory of
                  minors, harassing, discriminatory, threatening or hateful to any person;
                  or (c) information that infringes or violates any contractual or
                  intellectual property rights of others or the privacy or publicity
                  rights of others;
                </p>
                <p>
                  c. not to post, process, publish or transmit any content (including
                  works, data, materials (including without limitation), text, graphics,
                  images, information, audio material, audio-visual material, scripts,
                  software and files) that is illegal or unlawful; or infringe any
                  person’s legal rights; or capable of giving rise to legal action against
                  us or any person (in any jurisdiction and under any applicable law).
                </p>
                <p>
                  d. not to transmit, distribute, introduce or otherwise make available in
                  any manner through the Services any computer virus, keyloggers, malware,
                  spyware, worms, Trojan horses, time bombs or other malicious or harmful
                  code (collectively, “Harmful Code”) or to do any act capable of
                  disrupting the operation of the Platform; Please note that we do not
                  have an obligation to detect the presence of Harmful Code (as defined in
                  “Restrictions on the use of your Services” below. Please note that if
                  you download any Content from the Platform, you do so at your own risk.
                </p>
                <p>
                  e. not to use the Platform in any manner that could damage, disable or
                  impair our services or networks;
                </p>
                <p>
                  f. not to attempt to gain unauthorized access to the Platform or any
                  user accounts or computer systems or networks, through hacking, password
                  mining or any other unlawful means;
                </p>
                <div className="h2">11. Intellectual Property</div>
                <p>
                  a. Kochure shall remain the owner of the know-how, trademarks, service
                  marks, logos, slogans, patents, copyright or other intellectual property
                  rights belonging to it within or outside the Platform. The use of the
                  Platform, and nothing contained herein shall create nor transfer any
                  intellectual property right belonging to Kochure, and you are not
                  allowed to use any such rights belonging to Kochure without our written
                  consent.
                </p>
                <p>
                  b. If you believe that any content on the Platform infringes upon your
                  copyrights, please contact us through the contact details below.
                </p>
                <div className="h2">12. Financial Partners</div>
                <p>
                  At Kochure, we partner with other financial/payment service providers in
                  providing our Services to you. In order to enable you make payments
                  on/through the Platform, we may share your information with these
                  financial partners. You therefore authorize us to share your identity
                  and banking information with partner financial institutions in respect
                  of our Services. Kochure shall not be liable for any default, negligence
                  or breach of the financial partners AML policy as such can implement any
                  sanction, freeze account in that regard.
                </p>
                <div className="h2">13. Termination/Suspension</div>
                <p>
                  a. In the event of any breach of this Terms of Use, Kochure shall have
                  the right to suspend or terminate all or a portion of the Services to
                  you in our discretion or restrict your access to the Platform. We
                  reserve the right to revoke, terminate or suspend any privileges
                  associated with accessing the Services for any reason (including
                  regulatory instruction) or for no reason whatsoever. You agree that
                  Kochure shall not be liable to you or any third party for any
                  termination or suspension of your access to the Platform.
                </p>
                <p>
                  b. Termination of your access to the Platform shall be without prejudice
                  to any payment or sum due to you as at the date of termination or
                  suspension of your account. Provided that if the termination or
                  suspension of your account was a result of your breach of this Terms of
                  Use which resulted in any loss, penalty or damage to Kochure, we shall
                  be entitled to set off such loss from any sum due to you. You shall be
                  notified of the extent of the loss caused as a result of your breach
                  (where quantifiable) and the extent of the set off applied to any sum
                  due to you from Kochure.
                </p>
                <p>
                  c. If you wish to close your account, you may do so by contacting
                  Kochure’s customer service through the contact details below. In the
                  event of an abrupt request closure of your account, Kochure shall be at
                  liberty to charge or deduct any assessed charges, costs, taxes and any
                  assessed damages (where applicable) prior to the refund of any sum due
                  to you. Unless otherwise specified and agreed, you shall remain
                  obligated to pay all outstanding charges, if any, relating to your use
                  of the Platform prior to such closure request.
                </p>
                <div className="h2">14. Links to other Platforms</div>
                <p>
                  The Platform contain links to other platforms/websites or resources on
                  the internet. When you access third party platforms/websites, you do so
                  at your own risk. Links to other platforms/websites do not constitute an
                  endorsement, control, guarantee, authorization or representation of our
                  affiliation with those third parties. Kindly note that this Terms of Use
                  or our Privacy Policy do not apply to those third-party Platforms, as
                  such, you agree that Kochure shall not be liable under any circumstances
                  in relation to your use of such other platforms/websites.
                </p>
                <div className="h2">
                  15. No financial, investment or sponsorship advice
                </div>
                <p>
                  a. This Platform provides preliminary and general information about
                  savings and investment opportunities and is intended for initial
                  reference purposes only. Nothing on the Platform should be interpreted
                  as providing or intending to provide financial or investment advice. You
                  acknowledge that Kochure has not provided any financial, investment,
                  legal or tax advice and/or any other advice of any kind. You further
                  agree that your use of the Platform or any of the Services on the
                  Platform is based on your careful consideration, due diligence and/or
                  based on the independent advice sought from your advisors.
                </p>
                <p>
                  b. Any questions regarding anything on the Platform should be addressed
                  to Kochure using the contact information set below. Where Kochure
                  publishes information from independent or third-party sources (including
                  without limitation, newspapers, magazines and the internet) such
                  publication is for informational purposes, without more.
                </p>
                <div className="h2">16. Disclaimer of Warranty</div>
                <p>
                  a. The Platform, its content and all services on the platform are
                  provided “as is” and “as available” without any warranty of any kind,
                  express or implied. To the fullest extent permissible pursuant to
                  applicable law, Kochure, its affiliates and any person associated with
                  Kochure and its affiliates, disclaims all warranties of any kind, either
                  express or implied, statutory or otherwise, including but not limited to
                  any implied warranties of title, merchantability, fitness for a
                  particular purpose or non-infringement.
                </p>
                <p>
                  b. Without limiting the foregoing, Kochure does not warrant that access
                  to the Platform will be uninterrupted or error-free, or that defects, if
                  any, will be corrected within any specific timeframe; nor does Kochure,
                  its affiliates, nor any person associated with Kochure make any
                  representations about the accuracy, standard, reliability, currency,
                  quality, completeness, usefulness, performance, security, or suitability
                  of the Platform. Kochure does not warrant that the Platform is free of
                  viruses or other harmful components. You shall be solely and fully
                  responsible for any damage to any computer system, any loss of data, or
                  any improper use or improper disclosure of information caused by you or
                  any person using your login credentials. Kochure cannot and does not
                  assume any responsibility for any loss, damages or liabilities arising
                  from the failure of any telecommunications infrastructure, or the
                  internet or for your misuse of any of advice, ideas, information,
                  instructions or guidelines accessed through the Services. It is your
                  responsibility to install or download sufficient software or hardware
                  protection for your device or computer.
                </p>
                <div className="h2">17. Limitation of liability</div>
                <p>
                  a. Kochure and its subsidiaries, affiliates, officers, directors, agents
                  or employees are not liable to you or any other person for any punitive,
                  exemplary, consequential, incidental, indirect or special damages
                  (including, without limitation, any personal injury, lost profits,
                  business interruption, loss of programs or other data on your computer
                  or otherwise) arising from or in connection with your use of the
                  Platform, whether under a theory of breach of contract, tort,
                  negligence, strict liability, malpractice, any other legal or equitable
                  theory or otherwise, even if Kochure has been advised of the possibility
                  of such damages. You hereby release Kochure and hold it and its parents,
                  subsidiaries, affiliates, officers, directors, agents and employees
                  harmless from any and all claims, demands, and damages of every kind and
                  nature (including, without limitation, actual, special, incidental and
                  consequential), known and unknown, suspected and unsuspected, disclosed
                  and undisclosed, arising out of or in any way connected with your use of
                  the Services. You waive the provisions of any state or local law
                  limiting or prohibiting a general release.
                </p>
                <p>
                  b. The foregoing limitation of liability shall apply to the fullest
                  extent permitted by law in the applicable jurisdiction. This limitation
                  of liability also applies with respect to damages incurred by you by
                  reason of any products or services sold or provided on any linked
                  Platforms or otherwise by third parties other than Kochure and received
                  through the Services or any linked Platforms.
                </p>
                <p>
                  c. Without prejudice to the limitation of liability above, in the event
                  of any problem with the Platform or any of the services on the Platform,
                  you agree that your sole and exclusive remedy is to cease using the
                  Platform. Under no circumstances shall Kochure, its subsidiaries,
                  affiliates, officers, directors, agents or employees be liable in any
                  way for your use of Services, including, but not limited to, any errors
                  or omissions in the Platform, downtime, any infringement by the Platform
                  of the intellectual property rights or other rights of third parties, or
                  for any loss or damage of any kind incurred as a result of the use of
                  the Services.
                </p>
                <div className="h2">18. Breaches of Terms and Conditions</div>
                <p>
                  a. Without prejudice to our other rights under these terms and
                  conditions, if you breach these terms and conditions in any way, or if
                  we reasonably suspect that you have breached these terms and conditions
                  in any way, we shall:
                </p>
                <p>i. temporarily suspend your access to our Platform;</p>
                <p>ii. permanently prohibit you from accessing our Platform;</p>
                <p>iii. block your access to the Platform;</p>
                <p>
                  iv. commence legal action against you, whether for breach of contract or
                  otherwise; and/or
                </p>
                <p>v. suspend or delete your account on our Platform.</p>
                <p>
                  b. Kochure reserves the right to take any immediate action without
                  following the order listed above
                </p>
                <p>
                  c. Where we suspend or prohibit or block your access to our Platform or
                  a part of our Platform, you must not take any action to circumvent such
                  suspension or prohibition or blocking (including without limitation,
                  creating and/or using a different account).
                </p>
                <div className="h2">19. Indemnification</div>
                <p>
                  a. Without limiting the generality or effect of other provisions of this
                  Terms of Use, you agree to indemnify, hold harmless, and defend Kochure
                  and its subsidiaries, affiliates, officers, directors, affiliates,
                  agents and employees, parent companies, (collectively, “Indemnified
                  Parties” and each, individually, an “Indemnified Party”) against all
                  costs, expenses, liabilities and damages (including reasonable
                  attorney’s fees) incurred by any Indemnified Party in connection with
                  any third party claims arising out of: (i) your use of the Platform or
                  any part of it; (ii) your failure to comply with any applicable laws and
                  regulations; and (iii) your breach of any obligations set forth in this
                  Terms of Use.
                </p>
                <p>
                  b. This indemnity obligation shall survive this Terms of Use and your
                  use of the Platform. Kochure reserves the right to assume the exclusive
                  defense and control of any matter otherwise subject to indemnification
                  by you, in which event you will assist and cooperate with Kochure in
                  asserting any available defenses.
                </p>
                <div className="h2">20. Governing law and dispute resolution</div>
                <p>
                  a. This Terms of Use and all disputes and matters arising from the
                  Platform (or its use) shall be governed by the laws of the Federal
                  Republic of Nigeria.
                </p>
                <p>
                  b. In the event of a controversy, claim or dispute arising out of or
                  relating to this Terms of Use, the Parties shall attempt in good faith
                  to resolve such controversy, claim or dispute promptly by negotiation
                  between the parties or their authorized representatives. You shall,
                  before exploring any other remedy in law, notify Kochure of the dispute
                  or complaint through the contact details below. If parties are unable to
                  resolve the controversy, claim or dispute, the parties shall be at
                  liberty to explore any other dispute resolution mechanism known to Law
                  including mediation, arbitration or litigation.
                </p>
                <div className="h2">21. Miscellaneous</div>
                <p>
                  a. Force Majeure: Neither Party shall be considered in default of this
                  Agreement or liable for or held responsible for any delays or failures
                  in a Party’s performance of its obligations caused by fires, third party
                  labor disputes or strikes, embargoes, war, acts of terrorism or
                  sabotage, epidemics, pandemics, quarantine restrictions, insurrection,
                  riots, delays of carriers or suppliers, severe storms, earthquakes, snow
                  storms, fires, floods, tsunamis or other acts of God or by public enemy,
                  thefts, or acts, omissions or other causes beyond a Party’s reasonable
                  control, or without the fault or negligence of such Party, whether or
                  not similar to the foregoing (each a “Force Majeure Event”). The
                  Affected parties of a Force Majeure shall not be liable or have any
                  responsibility of any kind for any loss or damage incurred or suffered
                  by the other party; provided that the Affected party shall use all
                  reasonable efforts to minimize the effects of the Force Majeure events
                  and shall resume the performance of its obligations as soon practicable
                  after the cease of the causal event.
                </p>
                <p>
                  a. Force Majeure: Neither Party shall be considered in default of this
                  Agreement or liable for or held responsible for any delays or failures
                  in a Party’s performance of its obligations caused by fires, third party
                  labor disputes or strikes, embargoes, war, acts of terrorism or
                  sabotage, epidemics, pandemics, quarantine restrictions, insurrection,
                  riots, delays of carriers or suppliers, severe storms, earthquakes, snow
                  storms, fires, floods, tsunamis or other acts of God or by public enemy,
                  thefts, or acts, omissions or other causes beyond a Party’s reasonable
                  control, or without the fault or negligence of such Party, whether or
                  not similar to the foregoing (each a “Force Majeure Event”). The
                  Affected parties of a Force Majeure shall not be liable or have any
                  responsibility of any kind for any loss or damage incurred or suffered
                  by the other party; provided that the Affected party shall use all
                  reasonable efforts to minimize the effects of the Force Majeure events
                  and shall resume the performance of its obligations as soon practicable
                  after the cease of the causal event.
                </p>
                <p>
                  c. Severance: If any provision of this Terms of Use is prohibited by law
                  or judged by a court to be unlawful, void or unenforceable, the
                  provision shall, to the extent required, be severed from this Terms of
                  Use and rendered ineffective as far as possible without modifying the
                  remaining provisions of this Terms of Use, and shall not in any way
                  affect any other circumstances of or the validity or enforcement of this
                  Terms of Use.
                </p>
                <p>
                  d. Waiver: Failure to insist on performance of any of the terms of this
                  Terms of Use will not operate as a waiver of any subsequent default. No
                  waiver by Kochure of any right under this Terms of Use will be deemed to
                  be either a waiver of any other right or provision or a waiver of that
                  same right or provision at any other time.
                </p>
                <p>
                  e. Assignment: You may not assign, transfer or delegate your rights or
                  obligations hereunder, in whole or in part.
                </p>
                <p>
                  f. Bindingness: This Terms of Use shall be binding upon and inure to the
                  benefit of each of the parties and the parties’ respective successors
                  and permitted assigns. A printed version of this Terms of Use and of any
                  related notice given in electronic form shall be admissible in judicial
                  or administrative proceedings based upon or relating to this Terms of
                  Use to the same extent and subject to the same conditions as other
                  business documents and records originally generated and maintained in
                  printed form.
                </p>
                <div className="h2">22. Notice/Contact Details</div>
                <p>
                  In the case of any complaints or notice of dispute or any other
                  notification,
                </p>
                <p>please contact us at: Kochure Technologies Limited</p>
                <p>Email: Hello@kochure.com</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
