import React, { Component } from "react";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
class Sent extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content  p-5 ">
          <div className="page p-5">
            <div className="container">
              <div className="col-md-8 mx-auto">
                <h2 className="text-center text-success">Password reset successfully</h2>
                <p className="text-center pb-4">
                  Login to your app using your new password <br /> Thanks
                </p>
                {/* <a href="/account/recovery" className="btn btn-danger text-white btn-block w-100">
                  Retry
                </a> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Sent;
