import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import axios from "axios";
import * as ConstantVar from "../../constants_var";

class Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirm_password: "",
      isSubmitting: false,
    };
  }
  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  resetPassword = (e) => {
    e.preventDefault();
    if (this.state.password.length < 8) {
      window.iziToast.error({
        title: "Password must have a minimum of 8  characters",
        message: "",
        position: "topRight",
        theme: "light",
        color: "yellow",
      });
      return;
    }
    if (this.state.password !== this.state.confirm_password) {
      window.iziToast.error({
        title: "Password does not match",
        message: "",
        position: "topRight",
        theme: "light",
        color: "yellow",
      });
      return;
    }

    this.setState({ isSubmitting: true });
    let token = localStorage.getItem("resetToken");

    axios
      .post(ConstantVar.API_BASE_URL + "/auth/recovery/reset", {
        password: this.state.password,
        token: token,
      })
      .then(function (response) {
        if (response.data.status === "true") {
          window.iziToast.success({
            title:
              response.data.message.charAt(0).toUpperCase() +
              response.data.message.slice(1),
            message: "",
            position: "topRight",
            theme: "light",
            color: "green",
          });
          document.getElementById("gotoSuccess").click();
        }
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
        window.iziToast.error({
          title:
            err.response.data.message.charAt(0).toUpperCase() +
            err.response.data.message.slice(1),
          message: "",
          position: "topRight",
          theme: "light",
          color: "yellow",
        });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div className="page-content p-5">
          <div className="page p-5">
            <div className="container">
              <div className="col-md-6 col-sm-12 mx-auto">
                <h2 className="text-center">Reset password</h2>
                <p className="text-center pb-4"></p>

                <form onSubmit={this.resetPassword}>
                  <div className="form-group">
                    <label>New password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control p-3 mt-2 mb-2"
                      value={this.state.password}
                      onChange={this.handleChange}
                      name="password"
                    />
                  </div>

                  <div className="form-group">
                    <label>Re-type password</label>
                    <br />
                    <input
                      type="password"
                      className="form-control p-3 mt-2"
                      value={this.state.confirm_password}
                      onChange={this.handleChange}
                      name="confirm_password"
                    />
                  </div>
                  <button
                    disabled={this.state.isSubmitting}
                    type="submit"
                    className="btn btn-primary mt-4"
                  >
                    Update Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Link id="gotoSuccess" to="/account/recovery/success" type="hidden" />
        <Footer />
      </>
    );
  }
}

export default Update;
