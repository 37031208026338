import React from "react";
import VerifyToken from "./verify_token";

import { useParams } from "react-router";

function App() {
  let { token } = useParams();

  return <VerifyToken token={token} />;
}

export default App;
