import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { Component } from "react";
import { Helmet } from 'react-helmet'

export default class Privacy extends Component {
  render() {
    return (
      <>
        <Helmet>
          <link rel="canonical" href="https://kochure.com/privacy" />
          <title>Privacy</title>      
        </Helmet>
        <Header />
        <div className="page-content">
          <div className="page">
            <div className="header">
              <div className="col-12 col-md-10 mx-auto">
                <h1 className="headline">Privacy Policy</h1>
                <div className="my-3">Document effective October 1 , 2021</div>
              </div>
            </div>
            <div className="my-5 container has-animation">
              <div className="col-12 col-md-10 mx-auto">
                <div className="h2">Introduction</div>
                <p>
                  Kochure Technologies Limited (“Kochure” “we”, “us”, “our”),
                  recognizes your privacy rights as guaranteed under relevant
                  privacy laws. Thus, it is important to us as a law-abiding
                  organisation that your Personal Data is managed, processed and
                  protected in accordance with the provisions of the applicable
                  laws. In the course of our business and/or your engagement
                  with us and third parties through our Platforms (this includes
                  but are not limited to our websites, digital platforms, mobile
                  applications, physical operations/offices, amongst others), we
                  may process your Personal Data, subject however to the terms
                  of this Policy. This Privacy Policy (“Policy”) therefore
                  explains our privacy practices with respect to how we collect,
                  process your Personal Data and describes your rights as a user
                  of any of our services and Platforms.
                </p>
                <p>
                  This Policy applies to all our Platforms, and all related
                  sites, applications, services and tools regardless of how they
                  are accessed or used. In this Privacy Policy, “we”, “us” or
                  “our” refers to Kochure Technologies Limited its successors,
                  agents and assigns, while “you”, “your” refers to you and/or
                  any person who subscribes for, uses or authorizes the use of
                  the Service.
                </p>
                <p>
                  As a user of any of our Platforms, you accept this Privacy
                  Policy when you sign up for, or use our products, services,
                  content, technologies or functions offered on our Platforms
                  and all related sites and services (“Services”).
                </p>
                <p>
                  Our Privacy Policy explains our user’s privacy rights
                  regarding:
                </p>
                <p> a. What information we collect</p>
                <p>b. Why we collect such information</p>
                <p> c. The use of such information</p>
                <p>
                  d. The storage, sharing and protection of such information
                </p>
                <div className="h2">Definitions</div>
                <p>For the purpose of this Policy:</p>
                <p>
                  Personal Data/Information means information relating to you,
                  including your name, location data, online identifier address,
                  email address, pins, passwords, bank details, and other unique
                  identifiers such as but not limited to IP address, IMEI
                  number, Bank Verification Number, IMSI Number, SIM and others.
                </p>
                <p>
                  Process/Processing means any operation or set of operations
                  which is performed on your Personal Data or sets of Personal
                  Data, whether or not by automated means, such as collection,
                  recording, organisation, structuring, storage, adaptation or
                  alteration, retrieval, consultation, use, disclosure by
                  transmission, dissemination or otherwise making available,
                  alignment or combination, restriction, erasure or destruction.
                </p>
                <div className="h2">Consent</div>
                <p>
                  We kindly request that you carefully read through this Policy
                  and click on the ‘Agree’ icon below, if you consent to the
                  terms of this Policy, particularly with respect to the
                  processing of your Personal Data. That notwithstanding, if you
                  proceed to use any of our Platforms and services, it will
                  deemed that you have provided your express consent to the
                  terms of this Policy.
                </p>
                <div className="h2">Age</div>
                <p>
                  You must be at least 18 years old to use our services or any
                  of our Platforms. Individuals under the age of 18, or
                  applicable age of maturity, may utilize our Platforms services
                  only with involvement of a parent or legal guardian, under
                  such person’s account. Regardless, all Personal Data which we
                  may process shall be in accordance with this Policy and other
                  applicable laws.
                </p>
                <div className="h2">Collection of Personal Data</div>
                <p>
                  In the course of your engagement with us or with third parties
                  through our Platforms, we may collect your Personal Data in
                  the following ways:
                </p>
                <p>
                  Automatic Information collection: We may automatically collect
                  Information upon your engagement with us or our Platforms
                  through your computer, mobile phones and other access devices.
                  The information sent includes, but is not limited to, data
                  about our pages you access, geo-location information;
                  statistics on page views; traffic to and from our Platforms.
                </p>
                <p>
                  Information from downloads: When you download or use our
                  digital Platforms or access our site, we may receive non
                  personal information from your mobile device about your
                  location, number of downloads, geo location of downloads. You
                  may disable your location in the settings menu of the device.
                </p>
                <p>
                  Physically or Digitally Provided Information: We may collect
                  and process your information when you create and/or update
                  your account on our Platform; complete forms, questionnaires,
                  surveys etc. When you use our Services, we may also collect
                  information about your transactions and your activities. In
                  addition, when you open an account on our Platform, we may
                  collect information such as your contact information, which
                  includes your name, address, phone number, email or other
                  similar information; financial information such as your full
                  bank account numbers and/or credit card or debit card numbers.
                </p>
                <p>
                  Third Parties: We may also receive your information from third
                  parties such as, guardians, financial institutions, identity
                  verification services, vendors, and service providers etc.
                </p>
                <p>
                  Social Media: We also receive Information through your
                  engagements with us on social media sites (e.g., Facebook,
                  Instagram, LinkedIn, Twitter, etc). This includes but are not
                  limited to your replies to our posts, your comments,
                  enquiries, messages to us, etc. We may also collect
                  information from your public profile and updates on social
                  media.
                </p>
                <div className="h2">Accessing your Personal Information</div>
                <p>
                  When accessing our Platforms, we may collect information about
                  you and your interactions with the Services to which we
                  undertake to keep secure and confidential.
                </p>
                <p>
                  We may offer you the ability to connect with our Platforms
                  using a mobile device, either through a mobile application
                  (App), computer, mobile optimized website, or by any other
                  means. The provisions of this Privacy Policy apply to all such
                  mobile access and use of devices.
                </p>
                <p>
                  When you download or use our mobile applications, or access
                  one of our mobile optimized sites, we may receive information
                  about your location and your mobile device, including a unique
                  identifier for your device. We may use this information to
                  provide you with location-based services, such as advertising,
                  search results, Kochure’s updates, market trends and other
                  personalized content. Most mobile devices allow you to control
                  or disable location services in the device's setting's menu.
                  If you have questions about how to disable your device’s
                  location services, we recommend you contact your mobile
                  service carrier or the manufacture of your particular device.
                </p>
                <div className="h2">Using Your Personal Information</div>
                <p>
                  In the course of your engagements with us or through our
                  Platforms, we collect personal information for various legal
                  reasons, largely to enable us to personalize your experience
                  and to provide a more efficient service to you. Some of the
                  reasons we collect Information are to:
                </p>
                <p>provide services and customer support;</p>
                <p>
                  process transactions, payment requests, and send notices about
                  transactions;
                </p>
                <p>
                  create an account with us for the provision or use of our
                  services;
                </p>
                <p>
                  communicate with you about your account or transactions with
                  us and send you information or request feedback about features
                  on our website and applications or changes to our policies;
                </p>
                <p>
                  send you periodic emails and updates pertaining to our
                  products and services;
                </p>
                <p>
                  verify customers’ identity, including during account creation
                  and password (pin) reset processes;
                </p>
                <p>
                  manage your account and provide you with efficient customer
                  service,
                </p>
                <p>
                  send you offers and promotions for our services and investment
                  opportunities
                </p>
                <p>
                  resolve disputes, process payments and troubleshoot problems;
                </p>
                <p>
                  detect, investigate and prevent activities that may violate
                  our policies or be illegal;
                </p>
                <p>
                  manage risks, or to detect, prevent, and/or remediate fraud,
                  violation of policies and applicable user agreements or other
                  potentially prohibited or illegal activities;
                </p>
                <p>execute our contractual obligations to you;</p>
                <p>
                  improve our services and functionality by customizing user
                  experience;
                </p>
                <p>
                  measure the performance of our services and improve their
                  content and layout;
                </p>
                <p>
                  manage and protect our information technology infrastructure;
                </p>
                <p>
                  provide targeted marketing and advertising, provide service or
                  transaction update notices, and deliver promotional offers
                  based on communication preferences
                </p>
                <p>
                  obtain a means by which we may contact you; either by
                  telephone, text (SMS), email messaging, social media, etc;
                </p>
                <p>
                  conduct background checks, compare information for accuracy
                  and verify same with third parties;
                </p>
                <p>
                  identify or address a violation and administer our policies
                  and terms of use;
                </p>
                <p>
                  comply with legal, contractual and regulatory obligations;
                </p>
                <p>
                  execute your specific requests or use same for a specific
                  purpose as you may instruct;
                </p>
                <p>investigate and respond to your complaints or enquiries;</p>
                <p>
                  process your access to our services, Platforms, or functions
                  from time to time;
                </p>
                <p>
                  If we intend to use any Personal Information in any manner
                  that is not consistent with this Privacy Policy, you will be
                  informed of such anticipated use prior to or at the time at
                  which the Personal information is required and obtain your
                  consent.
                </p>
                <div className="h2">Storage and protection of your Data</div>
                <p>
                  We protect your personal Information using physical,
                  technical, and administrative security measures to reduce the
                  risks of loss, misuse, unauthorized access, unauthorised
                  disclosure and alteration. Some of our safeguards include
                  firewall protection, Virtual Private Network (VPN) protection,
                  encryption of data, authorization keys for authorised access
                  and strong passwords. Access to our various platforms is
                  restricted to authorized users only. Your Information is also
                  stored on our secure servers as well as secure physical
                  locations and cloud infrastructure (where applicable). Please
                  be aware that, despite our best efforts, no security measures
                  are perfect or impenetrable. We will retain your personal
                  information for the length of time needed to fulfil the
                  purposes outlined in this privacy policy unless a longer
                  retention period is required or permitted by law. To dispose
                  of personal data, we may anonymize it, delete it or take other
                  appropriate steps. Data may persist in copies made for backup
                  and business continuity purposes for additional time.
                </p>
                <p>
                  We will take all necessary measures to ensure that your
                  personal Data is safe, however, you are also required to
                  ensure that access codes, PINs, passwords, usernames, and all
                  other information or hints that may enable third party access
                  to your accounts on our Platforms are secure. We therefore
                  strongly advise you to keep such information secure and
                  confidential. If you use a third party’s device (laptops,
                  phones, public internet, etc.) to access your account, kindly
                  ensure that you always log out. Kindly note however those
                  certain devices are programmed to save passwords or usernames,
                  as such, we advise that you use third party devices with
                  extreme caution. If you believe that an unauthorized person
                  has accessed your information, please contact us immediately.
                </p>
                <div className="h2">Processing your Information</div>
                <p>
                  In order to execute our obligations to you or process your
                  transactions, we may be required to process your Information,
                  such as your name, account number, account ID, contact
                  details, shipping and billing address, or other information
                  needed to complete the transaction. We also work with third
                  parties, including financial institutions, vendors, service
                  providers, who at one point or the other facilitate
                  transactions executed on our Platforms. For completeness, in
                  the course of your engagement with us or use of our services
                  and Platforms, we may share your information with different
                  stakeholders, including but not limited to Financial
                  institutions; service providers; Credit bureaus and collection
                  agencies to report account or credit information; our
                  subsidiaries and affiliates; Regulatory or judicial
                  authorities; or other third parties pursuant to a subpoena,
                  court order, or other legal process or applicable requirement.
                  Please note that the aforementioned parties may be within or
                  outside Nigeria.
                </p>
                <p>
                  We may also process your Information when we believe, in our
                  sole discretion, that the disclosure of your Information is
                  necessary to comply with applicable laws and
                  judicial/regulatory orders; conduct investigations; manage
                  existing or imminent risks, prevent fraud, crime or financial
                  loss, or for public safety or to report suspected illegal
                  activity or to investigate violations of our Terms and
                  Conditions.
                </p>
                <p>
                  In all cases, we will ensure that your Information is safe,
                  and notify the receiving party of the confidential nature of
                  your Information, particularly the need to maintain the
                  confidentiality of same and prevent unlawful or unauthorised
                  usage.
                </p>
                <div className="h2">11. Intellectual Property</div>
                <p>
                  Kochure shall remain the owner of the know-how, trademarks,
                  service marks, logos, slogans, patents, copyright or other
                  intellectual property rights belonging to it within or outside
                  the Platform. The use of the Platform, and nothing contained
                  herein shall create nor transfer any intellectual property
                  right belonging to Kochure, and you are not allowed to use any
                  such rights belonging to Kochure without our written consent.
                  If you believe that any content on the Platform infringes upon
                  your copyrights, please contact us through the contact details
                  below.
                </p>
                <div className="h2">Your Rights</div>
                <p>
                  You have the following rights regarding your personal
                  information collected by us:
                </p>
                <p>
                  Right to access your personal information being held by us.
                  Request for such information may be sent to
                  (Hello@kochure.com)
                </p>
                <p>
                  Right to request that your personal data be made available to
                  you in an electronic format or that it should be sent to a
                  third party (Kindly note that we have the right to decline
                  such request if it is too frequent, unreasonable, and likely
                  to cause substantial cost to us. In any event, we may
                  communicate the reason for our refusal);{" "}
                </p>
                <p>
                  Right to rectify any inaccurate, incomplete information. As
                  such, if you discover any inaccuracy in your personal
                  information, kindly notify us promptly and provide us with
                  documentary evidence to enable us to update the requisite
                  changes.
                </p>
                <p>
                  Right to withdraw consent for the processing of your
                  information, provided that such withdrawal shall not
                  invalidate any processing hitherto done based on the consent
                  previously given by you;
                </p>
                <p>
                  Restrict or object to the processing of your personal data
                  provided that we may be compelled to process your data where
                  required under law, regulatory authorities, or court of law.
                </p>
                <p>
                  Right to request that your personal data be deleted. We may
                  however continue to retain the information where required
                  under law, contract, regulatory authorities, or court of law
                  or as the case maybe.
                </p>
                <div className="h2">11. Disclosures</div>
                <p>
                  We may share your personal information on the following
                  grounds:
                </p>
                <p>
                  Fraud Prevention and Risk Management: to help prevent fraud or
                  assess and manage risk.
                </p>
                <p>
                  Customer Service: for customer service purposes, including to
                  help service your accounts or resolve disputes.
                </p>
                <p>
                Legal Compliance: to help them comply with anti-money laundering and counter-terrorist financing verification requirements.
                </p>
                <p>
                Service Providers: to enable service providers under contract with us to support our business operations, such as fraud prevention, marketing, customer service and technology services. Our contracts dictate that these service providers only use your information in connection with the services they perform for us and not for their own benefit.
                </p>
                <div className="h2">Software</div>
                <p>
                If you download or use our cryptocurrency exchange or any other software product, an app, or a browser plugin, you agree that from time to time, the software may download and install upgrades, updates and additional features from us in order to improve, enhance, and further develop the software. We may utilise your personal information to internally evaluate our software.
                </p>
                <div className="h2">Period of Storage</div>
                <p>
                We retain your information for as long as necessary for the purpose(s) for which it was collected. The period of data storage is also subject to legal, regulatory, administrative and operational requirements.
                </p>
                <div className="h2">Exceptions</div>
                <p>
                Please note that this Policy does not apply to Information that is already in the public domain through no fault of ours.
                </p>
                <div className="h2">Violation</div>
                <p>
                If you violate the letter or spirit of this Policy, or otherwise create risk or possible legal exposure for us or attempt to violate the privacy rights of Kochure Technologies Limited and/or its other users, we reserve the right to restrict your access to our Platforms. We may notify you in the event that we are constrained to take such decision.
                </p>
                <div className="h2">Dispute resolution</div>
                <p>
                We are dedicated to ensuring that you are satisfied with our management of your Information. However, in the unlikely event that you have a complaint, please contact us via the details below, stating your name and details of your complaint. Upon receipt of your complaint we will endeavor to contact you within 3 (three) business days or more with a view to amicably resolving such dispute within the shortest possible time. The foregoing notwithstanding, all disputes arising from this policy shall first be resolved by negotiation. However, if parties fail to resolve the disputes amicably by such mutual consultations, parties shall further attempt to resolve the dispute by mediation.
                </p>
                <div className="h2">Contact Us</div>
                <p>
                If you have questions regarding your data privacy rights or would like to submit a related data privacy right request, kindly contact us via the information below:
                </p>
                <p>
                Hello@kochure.com
                </p>
                <p>
                Please allow up to 3 (three) business days or more for requests to be processed. We reserve the right to charge a reasonable fee to process excessive or repeated requests.
                </p>
                <div className="h2">Amendment</div>
                <p>
                We may amend or revise this Policy at any time by posting a revised version on our website. Notice of the amendment shall be posted on our website and the revised version will be effective from the date of publication. Kindly, note that your continued use of our Platform after the publication of the revised version constitutes your acceptance of our amended terms of the Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
