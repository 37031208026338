import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as ConstantVar from "../../constants_var";

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  resetPassword = (e) => {
    e.preventDefault();

    this.setState({
      isLoading: true,
    });

    let email = e.target.email.value;
    axios
      .post(ConstantVar.API_BASE_URL + "/auth/recovery/email", {
        email: email,
        type: "0",
      })
      .then((response) => {
        if (response.data.status === "true") {
          //reset was successful
          document.getElementById("gotoSent").click();
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error))
        this.setState({
          isLoading: false,
        });

        window.iziToast.error({
          title: "Unable to sent recovery link",
          message: "",
          position: "topRight",
          theme: "light",
          color: "yellow",
        });
      });
  };

  render() {
    return (
      <>
        <Header />
        <div
          className="page-content"
          style={{
            height: "700px",
          }}
        >
          <div className="page">
            <div className="header">
              <div className="col-12 col-md-10 mx-auto">
                <div className="headline">Account Recovery</div>
                <div className="my-3">
                  Provide your email address to reset your password
                </div>
              </div>
            </div>
            <div className="my-5 container col-md-4 col-sm-8 mx-auto">
              <form onSubmit={this.resetPassword}>
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    required
                    type="email"
                    name="email"
                    className="form-control p-3 mt-2"
                    placeholder="Email Address"
                  />
                </div>

                <button
                  disabled={this.state.isLoading}
                  type="submit"
                  className="col-12 btn btn-block btn-sm btn-primary"
                >
                  Send password recovery link
                </button>
              </form>
              <Link id="gotoSent" to="/account/recovery/sent" type="hidden" />
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Verify;
