import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { Component } from "react";
import { Helmet } from 'react-helmet'

export default class Home extends Component {
  render() {
    return (
      <>
      <Helmet>
        {/* <html lang="en" />   */}
        {/* <title>Blog</title> */}
        <link rel="canonical" href="https://kochure.com/about-us" />

        <meta 
          name="keywords"
          content="Bitcoin trading platform for newbies"
        />
      
      </Helmet>
        <Header />
        <div className="page-content">
          <div className="container about-us pt-4">
            <div className="headline col-12 col-md-8 mx-auto">
              Providing access to crypto services for all.
            </div>
            <div className="headline-note col-12 col-md-7 mx-auto ">
              We are building the cryptoeconomy – a more fair, accessible,
              efficient, and transparent financial system enabled by crypto.
            </div>

            <div className="banner mt-5 "></div>

            <div className="my-5 has-animation">
              <div className="heading">Our story</div>
              <div className="note mt-4">
                Kochure brings crypto and Blockchain solution to the unreached!
                To help make crypto and Blockchain products easily accessible to
                all. Kochure offers the easiest medium to buy, sell, send, and
                receive cryptocurrency. Kochure is your number one stop shop to
                trade all your Bitcoin and other crypto, bringing an awesome
                blockchain experience in your hands! We offer access to safe and
                secure wallets, with awesome customer service experience and a
                user friendly app. Kochure serves both the beginner and advanced
                trader in an awesome way.
              </div>
            </div>

            <div className="row has-animation">
              <div className="col-12 col-md-6">
                <div className="my-5">
                  <div className="heading">Mission</div>
                  <div className="note mt-4">
                    Bringing Crypto and Blockchain solution to the unreached!
                  </div>
                </div>

                <div className="my-5">
                  <div className="heading">Vision</div>
                  <div className="note mt-4">
                    To help make crypto and blockchain products easily
                    accessible to all!
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="banner-2 mx-4"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
