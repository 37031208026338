import { Link } from "react-router-dom";
import * as ConstantVar from "../../constants_var";

const BigBlogCard = (props) => {
  const style = {
    backgroundImage:
      `url(${ConstantVar.API_BASE_URL}/static/blog/` + props.post._id + "/featured.png)",
  };
  return (
    <>
      <div className="vertical-blog-card">
        <div className="blog-card-image" style={style}></div>

        <div className="mt-0 mt-md-4 mb-4">
          <div className=" post-title">
            <a href={"/blog/posts/" + props.post.url}>{props.post.title}</a>
            <div className="short-text">{props.post.caption}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BigBlogCard;
