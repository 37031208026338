import { Routes, Route } from "react-router-dom";

import Home from "../../pages/Home";
import AboutUs from "../../pages/AboutUs";
import Blog from "../../pages/Blog/Blog";
import SignUp from "../../pages/Signup";
import BlogPost from "../../pages/Blog/BlogPost";
import Terms from "../../pages/TermsOfService";
import Privacy from "../../pages/Privacy";
import Cookies from "../../pages/Cookies";
import Verification from "../../pages/verification";

import PasswordRecovery from "../../pages/Recovery/index";
import PasswordRecoverySent from "../../pages/Recovery/sent";
import PasswordRecoverySuccess from "../../pages/Recovery/succes";
import PasswordRecoveryFailed from "../../pages/Recovery/failed";
import PasswordRecoveryUpdate from "../../pages/Recovery/update";
import PasswordRecoveryVerifyToken from "../../pages/Recovery/token";
import Games  from "../../pages/Games/games";
import GameScreen from "../../pages/Games/gameScreen";

const Main = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/blog/posts/:url" element={<BlogPost />} />
    <Route path="/signup/:referral_code" element={<SignUp />} />
    <Route path="/signup/" element={<SignUp />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/cookies" element={<Cookies />} />
    <Route path="/games" element={<Games />} />
    <Route path="/games/board" element={<GameScreen />} />
    <Route path="/account/verification/:stage/:token" element={<Verification />} />

    <Route path="/forgot_password" element={<PasswordRecovery />} />
    <Route path="/account/recovery/sent" element={<PasswordRecoverySent />} />
    <Route path="/account/recovery/failed" element={<PasswordRecoveryFailed />} />
    <Route path="/account/recovery/update" element={<PasswordRecoveryUpdate />} />
    <Route
      path="/account/recovery/verify/:token"
      element={<PasswordRecoveryVerifyToken />}
    />
    <Route path="/account/recovery/success" element={<PasswordRecoverySuccess />} />
  </Routes>
);

export default Main;
