import React, { useState } from "react";

const Icon = (props) => {
  const [width] = useState(props.width) || '16px';
  const [height] = useState(props.height) || '16px';
  const [icon] = useState(props.icon);
  const myStyle={
    backgroundImage:"url(" +icon+")",
    width: width,
    height: height,
    };
  return <div className="ui-icon" style={myStyle}></div>;
};

export default Icon;
