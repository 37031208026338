import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Icon from "./Icon";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="top-section px-3 row">
          <h4 className="headline">Trade crypto with ease!</h4>
          <div className="headline-note col-12 col-lg-6 mx-auto">
            It only takes a few minutes to start enjoying crypto benefits. Download
            Kochure on Google Play or the App Store.
          </div>
          <div className="mt-4">
            <a
              href="https://apps.apple.com/ng/app/kochure/id1595574962"
              className="d-inline-block me-4"
            >
              <Icon
                height="60px"
                width="150px"
                icon="/assets/images/icons/app-store.svg"
              ></Icon>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.kochure.kochureApp"
              className="d-inline-block me-4"
            >
              <Icon
                height="60px"
                width="150px"
                icon="/assets/images/icons/google-play.svg"
              ></Icon>
            </a>
            {/* <a className="d-inline-block me-4">
              <Icon
                height="60px"
                width="150px"
                icon="/assets/images/icons/web-coming-soon.svg"
              ></Icon>
            </a> */}
          </div>
        </div>
        <div className="row mt-5 px-3 middle-section">
          <div className="col-12 mt-5 col-md-3">
            <div className="logo">
              <img src={Logo} />
            </div>
          </div>
          <div className="col-12 my-5 col-md-9">
            <div className="row">
              <div className="col-6 col-md-3">
                <h6 className="list-headline">Products</h6>
                <ul>
                  <li>Buy BTC</li>
                  <li>Sell BTC</li>
                  <li>P2P</li>
                </ul>
              </div>
              <div className="col-6 col-md-3">
                <h6 className="list-headline">Company</h6>
                <ul>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>Market</li>
                  <li>FAQ</li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-md-3">
                <h6 className="list-headline">Legal</h6>
                <ul>
                  <li>
                    <Link to="/terms">Terms</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/cookies">Cookies</Link>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-md-3">
                <h6 className="list-headline">Contact Address</h6>
                <ul>
                  <li>hello@kochure.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-section">
          <div className="d-flex flex-row">
            <div className="d-flex flex-wrap">
              &copy; {new Date().getFullYear()} {" "}Kochure . All rights reserved.
            </div>
            <div className="flex-grow-1 d-flex flex-row justify-content-end">
              <div className="mx-2 d-inline-block">
                <a href="https://www.twitter.com/kochureglobal">
                  <Icon
                    height="18px"
                    width="18px"
                    icon="/assets/images/icons/twitter.svg"
                  />
                </a>
              </div>
              <div className="mx-2 d-inline-block">
                <a href="https://web.facebook.com/KochureGlobal">
                  <Icon
                    height="18px"
                    width="18px"
                    icon="/assets/images/icons/facebook.svg"
                  />
                </a>
              </div>
              <div className="mx-2 d-inline-block">
                <a href="https://www.instagram.com/kochureglobal/">
                  {" "}
                  <Icon
                    height="18px"
                    width="18px"
                    icon="/assets/images/icons/instagram.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
