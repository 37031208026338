import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { Component } from "react";
import { Helmet } from 'react-helmet'

export default class Cookies extends Component {
  render() {
    return (
      <>
       <Helmet>
          <link rel="canonical" href="https://kochure.com/cookies" />
          <title>Cookies</title>      
        </Helmet>
        <Header />
        <div className="page-content">
          <div className="page">
            <div className="header">
              <div className="col-12 col-md-10 mx-auto">
                <h1 className="headline">Cookies</h1>
                <div className="my-3">Document effective October 1 , 2021</div>
              </div>
            </div>
            <div className="my-5 container has-animation">
              <div className="col-12 col-md-10 mx-auto">
                <p>
                  Cookies are small pieces of data, stored in text files, that
                  are stored on your computer or other device when websites are
                  loaded in a browser, and which collects information about user
                  actions on a site.
                </p>
                <p>
                  When you access our Platforms, we may place small data files
                  on your computer or other device. These data files may be
                  cookies, pixel tags, flash cookies, or other local storage
                  provided by your browser or associated applications
                  (collectively referred to as cookies"). These technologies are
                  used to recognize users as customers; customize services,
                  content, and advertising; measure promotional effectiveness;
                  help ensure that account security is not compromised; mitigate
                  risk and prevent fraud; and to promote trust and safety on our
                  Platform. We also use cookies to collect and use data as part
                  of our services, recognise your browser or device, learn more
                  about your interests, and provide you with essential features
                  and services.
                </p>
                <p>
                  This Policy applies to our websites and Platforms alone. We do
                  not exercise control over the sites displayed or linked from
                  within our various services. These other sites may place their
                  own cookies or other files on your computer, collect data or
                  solicit personal information from you.
                </p>
                <div className="h2">What Type of Cookies Do We Use?</div>
                <p>
                  Temporary cookies: This is also known as 'session cookies'; it
                  helps our sites and platforms to recognise users, temporary
                  memorize their online activities and the information provided
                  when they navigate through a website. Session cookies only
                  retain information about your activities for as long as you
                  are on the website. Once the web browser is closed, the
                  cookies are automatically deleted.
                </p>
                <p>
                  Permanent cookies: This is also known as 'persistent cookies'
                  or ‘first-party cookies’; they remain in operation even after
                  the web browser has closed. It helps our sites or platforms
                  remember your information, preferences and settings such as
                  language selection and internal bookmarks for when you revisit
                  in the future.
                </p>

                <div className="h2">Why we use cookies?</div>
                <p>
                  Cookies store helpful information to enhance users'
                  experiences on our site, and possibly to improve our ability
                  to reconnect with you later. They are used to remember you and
                  your preferences such as your preferred language, device
                  settings, browsing activities and other useful information,
                  help you navigate between pages efficiently, and generally
                  improve your experience in using our services.
                </p>
                <p>We use these cookies as follows:</p>
                <p>
                  Authentication: We use cookies and similar technologies to
                  recognize you when you visit our site. If you’re signed into
                  our website or mobile app, it helps us show you the right
                  information and personalize your experience in line with your
                  settings. Cookies also enables us to identify you and verify
                  your account.
                </p>
                <p>
                  Security: We use cookies to make your interactions with our
                  services and platforms faster and more secure. For example, we
                  use cookies to enable and support our security features, keep
                  your account safe and to help us detect malicious activity and
                  violations of our Terms and Conditions of Use.
                </p>
                <p>
                  Preferences, features and services: We use cookies to enable
                  the functionality of our Services, such as helping you to fill
                  out forms on our Services easily and providing you with
                  features, insights and customized content. We also use it to
                  remember information about your activities on our platforms
                  and your preferences.
                </p>
                <p>
                  Performance, Analytics and Research: Cookies help us learn
                  more about how well our services perform in different
                  locations. We use cookies to determine and measure performance
                  and to learn whether you have interacted with our websites,
                  content or emails and provide analytics based on those
                  interactions. These cookies allow us to recognise and count
                  the number of visitors and to see how visitors move around our
                  website when they are using it.
                </p>

                <div className="h2">Where We Place Cookies</div>
                <p>
                  PWe set cookies in a number of different locations across our
                  services. These locations may include:
                </p>
                <p>
                  a. Our website (including www.kochure.com) and other Services;
                </p>
                <p>b. Our mobile application</p>
                <p>
                  c. E-mails (We may use cookies in some of our emails to enable
                  us understand how you interact with our emails. It also helps
                  us to improve our future email communications with you.
                  Depending on your email or browser settings, cookies in our
                  emails may be automatically accepted.)
                </p>
                <div className="h2">Controlling Cookies</div>
                <p>
                  We will always ask for your consent to accept the use of
                  cookies on your device. You can freely decline our cookies if
                  the web browser or browser add-on permits, unless our cookies
                  are required to prevent fraud or ensure the security of
                  Platforms we control. You may also configure your specific
                  browser or email settings to automatically cookies. You may
                  also erase cookies stored on your device through your browser
                  settings or by deleting the folder or file where they are
                  stored on your device.
                </p>
                <p>
                  Declining our Cookies may affect your ability to fully utilize
                  of our Platform and services. Without cookies you may not have
                  access to certain features on the site, including access to
                  certain personalized content.
                </p>
                <p>
                  Kochure this Cookies Policy to reflect changes in the law, our
                  data collection and use practices, the features of our site,
                  or advancements in technology. If we make any material
                  changes, we will notify you by email or through a prominent
                  notice on our sites/platforms prior to such change.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
