import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import Icon from "../components/shared/Icon";
import { Component } from "react";
import NumberFormat from "../components/shared/Number-Format";
import * as ConstantVar from "../../src/constants_var";

export default class Home extends Component {
  constructor(props) {
    super(props);
    const image = "/assets/images/blog-preview.png";

    this.state = {
      index: 1,
      coins: null,
      btc: null,
      eth: null,
      usdt: null,
      xrp: null,
      doge: null,
      shib: null,
      appData: null,
      style: {
        borderRadius: "50%",
        background: "#1d38e4",
        height: "30px",
        width: "30px",
        color: "#fff",
        margin: "auto",
        padding: "5px",
      },
      isLoaded: false,
    };

    setInterval(() => {
      if (this.state.index < 3) {
        this.setState({ index: this.state.index + 1 });
      } else {
        this.setState({ index: 0 });
      }
    }, 2000);
  }

  componentDidMount() {
    fetch(`${ConstantVar.API_BASE_URL}/general/market_data`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        const data = response.data;
        this.setState({
          coins: response.data,
          btc: data[0],
          eth: data[1],
          usdt: data[2],
          xrp: data[4],
          doge: data[3],
          shib: data[10],
        });

        // get app data informtion
        fetch(`${ConstantVar.API_BASE_URL}/general?app_version=1.0.0`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((response) => {
            const btcNairaVal = this.state.btc.price * response.data.app.buy_naira_rate;
            localStorage.setItem("btcNairaVal", btcNairaVal);
            localStorage.setItem("btc24hrs", this.state.btc._24hrs);
            this.setState({
              isLoaded: true,
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <section id="section-1" className="home mt-4">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 col-lg-6 ease-in postion-relative anim ps-lg-4"
                  id="get-kochure-1"
                >
                  <h1 className="headline">Buy and sell cryptocurrencies easily</h1>
                  <p className="headline-note mt-3">
                    A simple, secure and seamless crypto trading platform. Buy and Sell
                    Bitcoin, Ethereum, Tether and other Cryptocurrencies instantly at best
                    rates.
                  </p>
                  <div className="mt-4 buttons">
                    <a
                      className="d-inline-block me-4"
                      href="https://apps.apple.com/ng/app/kochure/id1595574962"
                    >
                      <Icon
                        height="60px"
                        width="150px"
                        icon="/assets/images/icons/app-store.svg"
                      ></Icon>
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.kochure.kochureApp">
                      <Icon
                        height="60px"
                        width="150px"
                        icon="/assets/images/icons/google-play.svg"
                      ></Icon>
                    </a>
                  </div>
                </div>
                
                <div className="col-12 col-lg-6 pe-0">
                  <div id="home-section-1-bg">
                    <div className="position-relative">
                      <div id="home-balance" className="d-none d-lg-block">
                        <div className="content">
                          <div className="heading">Total Wallet Balance</div>
                          {this.state.btc && (
                            <>
                              <div id="balance">
                                ${" "}
                                <NumberFormat
                                  number={this.state.btc.price}
                                  noOfDecimal={2}
                                />
                              </div>
                              <div id="btc" className="d-flex mt-4 flex-row">
                                <div className="flex-wrap">
                                  <span>BTC</span>
                                  <span>1</span>
                                </div>
                                <div className="flex-grow-1 d-flex  justify-content-end">
                                  <span>
                                    {this.state.btc._24hrs}%
                                    <Icon icon="/assets/images/icons/arrow-up-white.svg"></Icon>
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="section-2" className="home has-animation">
            {this.state.coins && (
              <>
                <div className="">
                  <div className="row mx-2 px-4 px-lg-5">
                    <div
                      className={
                        "col-12 col-md-6 my-3 col-lg-3" +
                        (this.state.index == 0 ? " bg-gray " : "")
                      }
                    >
                      <div>
                        <Icon
                          icon="/assets/images/icons/coin-btc.svg"
                          width="40px"
                          height="40px"
                        ></Icon>
                      </div>
                      <div className="mt-4 coin-pair">
                        <span className="small">BTC/USD</span>
                        <span
                          className={
                            "small" + (this.state.btc._24hrs < 0 ? " down" : " up")
                          }
                        >
                          {this.state.btc._24hrs}%
                        </span>
                      </div>

                      <div className="mt-2 coin-value">
                        <NumberFormat number={this.state.btc.price} />
                      </div>
                      <div className="mt-2 small coin-value-small">
                        <NumberFormat number={this.state.btc.price} />
                      </div>
                    </div>
                    <div
                      className={
                        "col-12 col-md-6 my-3 col-lg-3" +
                        (this.state.index == 1 ? " bg-gray " : "")
                      }
                    >
                      <div>
                        <Icon
                          icon="/assets/images/icons/coin-eth.svg"
                          width="40px"
                          height="40px"
                        ></Icon>
                      </div>
                      <div className="mt-4 coin-pair">
                        <span className="small">ETH/USD</span>
                        <span
                          className={
                            "small" + (this.state.eth._24hrs < 0 ? " down" : " up")
                          }
                        >
                          {this.state.eth._24hrs}%
                        </span>
                      </div>
                      <div className="mt-2 coin-value">
                        <NumberFormat number={this.state.eth.price} />
                      </div>
                      <div className="mt-2 small coin-value-small">
                        <NumberFormat number={this.state.eth.price} />
                      </div>
                    </div>
                    <div
                      className={
                        "col-12 col-md-6 my-3 col-lg-3" +
                        (this.state.index == 2 ? " bg-gray " : "")
                      }
                    >
                      <div>
                        <Icon
                          icon="/assets/images/icons/coin-xrp.svg"
                          width="40px"
                          height="40px"
                        ></Icon>
                      </div>
                      <div className="mt-4 coin-pair">
                        <span className="small">XRP/USD</span>
                        <span
                          className={
                            "small" + (this.state.xrp._24hrs < 0 ? " down" : " up")
                          }
                        >
                          {this.state.xrp._24hrs}%
                        </span>
                      </div>
                      <div className="mt-2 coin-value">
                        <NumberFormat number={this.state.xrp.price} />
                      </div>
                      <div className="mt-2 small coin-value-small">
                        <NumberFormat number={this.state.xrp.price} />
                      </div>
                    </div>
                    <div
                      className={
                        "col-12 col-md-6 my-3 col-lg-3" +
                        (this.state.index == 3 ? " bg-gray " : "")
                      }
                    >
                      <div>
                        <Icon
                          icon="/assets/images/icons/coin-doge.svg"
                          width="40px"
                          height="40px"
                        ></Icon>
                      </div>
                      <div className="mt-4 coin-pair">
                        <span className="small">DOGE/USD</span>
                        <span
                          className={
                            "small" + (this.state.doge._24hrs < 0 ? " down" : " up")
                          }
                        >
                          {this.state.doge._24hrs}%
                        </span>
                      </div>
                      <div className="mt-2 coin-value">
                        <NumberFormat number={this.state.doge.price} />
                      </div>
                      <div className="mt-2 small coin-value-small">
                        <NumberFormat number={this.state.doge.price} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>

          <section id="section-3" className="home has-animation">
            <div className="container">
              <div className="row">
                <div className="col-5 d-none d-lg-block">
                  <div className="app-screen"></div>
                </div>
                <div className="col-12 px-5 col-lg-7">
                  <h2 className="headline">Crypto Trading, Simplified on Kochure</h2>
                  <div className="headline-note mt-3">
                    At Kochure, we provide an enabling environment for you to make massive
                    success in your crypto journey.
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 mb-5 col-lg-6">
                      <div className="bullet-icon">
                        <Icon
                          width="48px"
                          height="73px"
                          icon="/assets/images/icons/manage.svg"
                        ></Icon>
                      </div>
                      <h4 className="heading mt-2">Manage your portfolio</h4>
                      <div className="heading-note mt-2">
                        Buy and sell popular digital currencies, keep track of them in one
                        place.
                      </div>
                    </div>
                    <div className="col-12 mb-5 col-lg-6">
                      <div className="bullet-icon">
                        <Icon
                          width="48px"
                          height="73px"
                          icon="/assets/images/icons/buy-sell.svg"
                        ></Icon>
                      </div>
                      <h4 className="heading mt-2">Instant buy & sell</h4>
                      <div className="heading-note mt-2">
                        Your Kochure account is credited instantly when you buy & sell
                        coins
                      </div>
                    </div>
                    <div className="col-12 mb-5 col-lg-6">
                      <div className="bullet-icon">
                        <Icon
                          width="48px"
                          height="73px"
                          icon="/assets/images/icons/secure.svg"
                        ></Icon>
                      </div>
                      <h4 className="heading">Secure Wallet</h4>
                      <div className="heading-note mt-2">
                        Store your cryptocurrencies in a secure wallet
                      </div>
                    </div>
                    <div className="col-12 mb-5 col-lg-6">
                      <div className="bullet-icon">
                        <Icon
                          width="48px"
                          height="73px"
                          icon="/assets/images/icons/data-bills.svg"
                        ></Icon>
                      </div>
                      <h4 className="heading">Data and Bills</h4>
                      <div className="heading-note mt-2">
                        Buy airtime, data and pay all bills with crypto
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="section-4" className="home ">
            <div className="container">
              <h2 className="headline" id="market">
                Market trend
              </h2>
              <div className="table-responsive mt-4 has-animation">
                {this.state.coins && (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>Name</td>
                          <td>Price</td>
                          <td>24h change</td>
                          <td>Chart</td>
                          <td>Trade</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td className="td-coin-pair">
                            <Icon
                              icon="/assets/images/icons/coin-btc.svg"
                              width="40px"
                              height="40px"
                            ></Icon>
                            <span className="long-name">Bitcoin</span>
                            <span className="short-name">BTC</span>
                          </td>
                          <td className="price">
                            $<NumberFormat number={this.state.btc.price} />
                          </td>
                          <td className={this.state.btc._24hrs < 0 ? " down" : "up"}>
                            {this.state.btc._24hrs}%
                          </td>
                          <td>
                            <img
                              src={
                                this.state.btc._24hrs < 0
                                  ? "/assets/images/graph-down.svg"
                                  : "/assets/images/graph-up.svg"
                              }
                            />
                          </td>
                          <td>
                            <a className="trade-btn">Trade</a>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td className="td-coin-pair">
                            <Icon
                              icon="/assets/images/icons/coin-eth.svg"
                              width="40px"
                              height="40px"
                            ></Icon>
                            <span className="long-name">Etherum</span>
                            <span className="short-name">ETH</span>
                          </td>
                          <td className="price">
                            $<NumberFormat number={this.state.eth.price} />
                          </td>
                          <td className={this.state.eth._24hrs < 0 ? " down" : "up"}>
                            {this.state.eth._24hrs}%
                          </td>
                          <td>
                            <img
                              src={
                                this.state.eth._24hrs < 0
                                  ? "/assets/images/graph-down.svg"
                                  : "/assets/images/graph-up.svg"
                              }
                            />
                          </td>
                          <td>
                            <a className="trade-btn">Trade</a>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td className="td-coin-pair">
                            <Icon
                              icon="/assets/images/icons/coin-xrp.svg"
                              width="40px"
                              height="40px"
                            ></Icon>
                            <span className="long-name">Ripple</span>
                            <span className="short-name">XRP</span>
                          </td>
                          <td className="price">
                            $<NumberFormat number={this.state.xrp.price} />
                          </td>
                          <td className={this.state.xrp._24hrs < 0 ? " down" : "up"}>
                            {this.state.xrp._24hrs}%
                          </td>
                          <td>
                            <img
                              src={
                                this.state.xrp._24hrs < 0
                                  ? "/assets/images/graph-down.svg"
                                  : "/assets/images/graph-up.svg"
                              }
                            />
                          </td>
                          <td>
                            <a className="trade-btn">Trade</a>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td className="td-coin-pair">
                            <Icon
                              icon="/assets/images/icons/coin-doge.svg"
                              width="40px"
                              height="40px"
                            ></Icon>
                            <span className="long-name">DOGE</span>
                            <span className="short-name">DOGE</span>
                          </td>
                          <td className="price">
                            $<NumberFormat number={this.state.doge.price} />
                          </td>
                          <td className={this.state.doge._24hrs < 0 ? " down" : "up"}>
                            {this.state.doge._24hrs}%
                          </td>
                          <td>
                            <img
                              src={
                                this.state.doge._24hrs < 0
                                  ? "/assets/images/graph-down.svg"
                                  : "/assets/images/graph-up.svg"
                              }
                            />
                          </td>
                          <td>
                            <a className="trade-btn">Trade</a>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td className="td-coin-pair">
                            <Icon
                              icon="/assets/images/icons/coin-shiba.svg"
                              width="40px"
                              height="40px"
                            ></Icon>
                            <span className="long-name">SHIBA</span>
                            <span className="short-name">SHIB</span>
                          </td>
                          <td className="price">
                            $<NumberFormat number={this.state.shib.price} />
                          </td>
                          <td className={this.state.shib._24hrs < 0 ? " down" : "up"}>
                            {this.state.shib._24hrs}%
                          </td>
                          <td>
                            <img
                              src={
                                this.state.shib._24hrs < 0
                                  ? "/assets/images/graph-down.svg"
                                  : "/assets/images/graph-up.svg"
                              }
                            />
                          </td>
                          <td>
                            <a className="trade-btn">Trade</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </section>

          <section id="section-5" className="home px-2 ">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 has-animation">
                  <h3 className="headline mt-5">
                    Most trusted cryptocurrency platform
                  </h3>

                  <div className="higlight-card mt-5">
                    <div className="d-flex flex-row ">
                      <div className="d-flex icon-holder flex-wrap  align-content-center">
                        <Icon
                          className="mx-auto d-inline-block"
                          icon="/assets/images/icons/folder.svg"
                          height="25px"
                          width="25px"
                        ></Icon>
                      </div>
                      <div className=" px-3 flex-grow-1">
                        <h4 className="card-headline">Secure storage</h4>
                        <div className="card-headline-note mt-2">
                          We store the vast majority of the assets in secure offline
                          storage.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="higlight-card mt-4">
                    <div className="d-flex flex-row ">
                      <div className="icon-holder ">
                        <Icon
                          className="mx-auto d-inline-block"
                          icon="/assets/images/icons/wallet.svg"
                          height="25px"
                          width="25px"
                        ></Icon>
                      </div>
                      <div className=" px-3 flex-grow-1">
                        <h4 className="card-headline">Multiple payment options</h4>
                        <div className="card-headline-note mt-2">
                          We offer a variety of payment methods to buy bitcoin online.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="higlight-card mt-4">
                    <div className="d-flex flex-row ">
                      <div className="icon-holder d-flex flex-wrap align-content-center">
                        <Icon
                          className="mx-auto d-inline-block"
                          icon="/assets/images/icons/chart.svg"
                          height="25px"
                          width="25px"
                        ></Icon>
                      </div>
                      <div className=" px-3 flex-grow-1">
                        <h4 className="card-headline">More assets</h4>
                        <div className="card-headline-note mt-2">
                          Trade in multiple markets and with your favourite coin pairs.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7">
                  <div className="app-screenshot-2 mx-auto"></div>
                </div>
              </div>
            </div>
          </section>

          <section id="section-6">
            <div className="container has-animation">
              <div className="headline">What Our Customer Says</div>
            </div>
            <div className="testimonials-section">
              <div className="testimonial-cards col-12 col-lg-8 mx-auto">
                <div className="t-card card-item col-md-3 col-sm-6">
                  <div>
                    This app is super amazing, great interface with unbeatable p2p
                    transactions. It’s great to receive Btc from any country. I duly
                    recommend.
                  </div>
                  <div className="d-flex flex-row my-3">
                    <div
                      className="d-flex flex-wrap align-content-center testitomonial-image"
                      style={this.state.style}
                    >
                      {" "}
                      BR
                    </div>
                    <div className="flex-grow-1 px-2 d-flex align-items-center">
                      Boma Richard
                    </div>
                  </div>
                </div>
                <div className="t-card card-item col-md-3 col-sm-6">
                  <div>
                    Your customer service is the best, how you interact with your
                    customers, this is the best I have seen in the crypto space.
                  </div>
                  <div className="d-flex flex-row my-3">
                    <div
                      className="d-flex flex-wrap align-content-center testitomonial-image"
                      style={this.state.style}
                    >
                      AS
                    </div>
                    <div className="flex-grow-1 px-2 d-flex align-items-center">
                      Adeyemi Solomon
                    </div>
                  </div>
                </div>

                <div className="t-card card-item col-md-3 col-sm-6">
                  <div>
                    Kochure has been amazing for me so far. Very easy to buy and sell,
                    send and receive Bitcoin.
                  </div>
                  <div className="d-flex flex-row my-3">
                    <div
                      className="d-flex flex-wrap align-content-center testitomonial-image"
                      style={this.state.style}
                    >
                      JC
                    </div>
                    <div className="flex-grow-1 px-2 d-flex align-items-center">
                      Johnson Collins
                    </div>
                  </div>
                </div>

                <div className="t-card card-item col-md-3 col-sm-6">
                  <div>
                    Very simple to use, this app has the best user interface, I hoogely
                    recommend ❤️✌️
                  </div>
                  <div className="d-flex flex-row my-3">
                    <div
                      className="d-flex flex-wrap align-content-center"
                      style={this.state.style}
                    >
                      BO
                    </div>
                    <div className="flex-grow-1 px-2 d-flex align-items-center">
                      Benedita Okonkwo
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}
