import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import BigBlogCard from "../../components/shared/Big-Blog-Card";
import HorizotalBlogCard from "../../components/shared/Horizontal-Blog-Card";
import VerticalBlogCard from "../../components/shared/Vertical-Blog-Card";
import { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var";
import { Helmet } from 'react-helmet'

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      posts: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get(ConstantVar.API_BASE_URL + "/general/blog", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        this.setState({
          posts: response.data.data,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <>
      <Helmet>
        {/* <html lang="en" />   */}
        <link rel="canonical" href="https://kochure.com/blog" />

        <meta 
          name="keywords"
          content="Bitcoin trading platform for newbies"
        />
        <title>Blog</title>

      
      </Helmet>
        <Header />
        <div className="page-content">
          {!this.state.isLoading ? (
            <>
              <div className="bg-gray ">
                <div className="container px-4">
                  <section className="top-posts">
                    <h1 className="page-title">Blog</h1>
                    <div className="row mt-5">
                      <div className="col-12 col-sm-7 col-md-5 ">
                        {this.state.posts[0] && (
                        <BigBlogCard post={this.state.posts[0]} />
                         
                        )}
                      </div>
                      <div className="col-12 col-sm-5 col-md-7">
                        <div className="px-md-4">
                          {this.state.posts[1] && (
                            <HorizotalBlogCard post={this.state.posts[1]} />
                          )}
                          {this.state.posts[2] && (
                            <HorizotalBlogCard post={this.state.posts[2]} />
                          )}
                          {this.state.posts[3] && (
                            <HorizotalBlogCard post={this.state.posts[3]} />
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="container px-4 has-animation">
                <div className="row py-5">
                  {this.state.posts.length > 4 && (
                    <>
                      {this.state.posts.map((post, index) => {
                        return (
                          index > 3 && (
                            <div className="col-12 col-md-4 col-lg-3">
                              <VerticalBlogCard post={post} />
                            </div>
                          )
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="text-center p-5 m-5">
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}
