import { Link } from "react-router-dom";
import Icon from "./Icon";
import moment from "moment";
import * as ConstantVar from "../../constants_var";

const BigBlogCard = (props) => {
  const style = {
    backgroundImage:
      `url(${ConstantVar.API_BASE_URL}/v1/static/blog/` + props.post._id + "/featured.png)",
  };
  return (
    <>
      <div className="horizontal-blog-card">
        <div className="row mb-4 mt-4">
          <div className="col-12 col-md-6 d-none d-md-block">
            <Link to={"/blog/posts/" + props.post.url} className="text-dark">
              {" "}
              <div className="post-title">{props.post.title}</div>
            </Link>
            <div className="short-text">{props.post.caption}</div>

            <div className="mt-2 d-none d-lg-block post-footer">
              <div className="time-stamp">
                {moment(props.post.created_at).format("MMM DD, YYYY")}
              </div>
              <Link to={"/blog/posts/" + props.post.url} className="learn-more">
                Learn more{" "}
                <Icon
                  height="16px"
                  width="16px"
                  icon="/assets/images/icons/arrow-right.svg"
                />
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 ">
            <div className="blog-card-image" style={style}></div>
          </div>
          <div className="col-12 col-md-6 d-block d-md-none">
            <Link to={"/blog/posts/" + props.post.url} className="text-dark">
              {" "}
              <div className="post-title">{props.post.title}</div>
            </Link>
            <div className="time-stamp my-2 d-block d-md-none">
              {" "}
              {moment(props.post.created_at).format("MMM DD, YYYY")}
            </div>
            <div className="short-text">{props.post.caption}</div>

            <div className="mt-2 d-none d-lg-block post-footer">
              <div className="timestamp">
                {" "}
                {moment(props.post.created_at).format("MMM DD, YYYY")}
              </div>
              <Link to={"/blog/posts/" + props.post.url} className="learn-more">
                Learn more{" "}
                <Icon
                  height="16px"
                  width="16px"
                  icon="/assets/images/icons/arrow-right.svg"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BigBlogCard;
