import React from "react";
import "./App.css";
import Main from "./components/Main/Main";
import $ from "jquery";
import { Helmet } from 'react-helmet'

function App() {
  $(window).scroll(function () {
    $(".has-animation").each(function () {
      var top_of_element = $(this).offset().top;
      var bottom_of_element = $(this).offset().top + $(this).outerHeight();
      var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
      var top_of_screen = $(window).scrollTop();
      if (
        (bottom_of_screen - 30) > top_of_element &&
        top_of_screen < bottom_of_element
      ) {
        $(this).addClass("visible");
      } else {
        $(this).removeClass("visible");
      }
    });
  })
  return (
    <div className="App" >
      
      <Helmet>
        <html lang="en" />  
        <title></title>
        <meta 
          name="keywords"
          content="Buy and sell cryptocurrencies easily, Crypto trading-simplified on Kochure, 
          Trade with zero worries anytime, Easily convert your crypto to cash, Where to sell bitcoin in Nigeria,
          The best Crypto wallet in Nigeria, Invest in crypto with your smartphone,
          Best crypto trading platform in Nigeria for Beginners, Send and receive crypto payments convert to cash in minutes!, 
          The most secured crypto exchange, Trade your Bitcoin and Altcoins at the Best market rates, Best App to buy and sell crypto for Nigerians, 
          Trade cryptocurrency and grow your portfolio with Kochure, The easiest platform to get started with crypto, 
          Convert your crypto to cash at best rates, no middleman needed"
        />
        <meta 
          name="description" 
          content="Kochure trading platform - We operate the Best crypto trading plaform in Nigeria" 
          data-shuvi-head="true"
        />

        {/* <meta 
          name="keywords"
          content="fashion, male-wears, men's clothing, men's shoes, men's accessories"
        /> */}
      </Helmet>
      
      
      <Main />
    </div>
  );
}

export default App;
