const NumberFormat = (props) => {
  return (
    <span>
      {props.number.toLocaleString(undefined, {
        maximumFractionDigits: props.noOfDecimal >= 0 ? props.noOfDecimal : 10,
      })}
    </span>
  );
};

export default NumberFormat;
