import React, { Component } from "react";
import { app } from "./firebaseConfig";
import { getDatabase, get, onValue, ref, set } from "firebase/database";
import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";

export default class GameScreen extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isRegistered: false,
      isStarted: false,
      isDisabled: false,
      joinedGame: false,
      questionCount: 0,
      questions: this.questions,
      isClicked: false,
      scoredPoint: 0,
      countDown: 10,
      standing: [],
      timer: 0, // Countdown time in seconds
      counting: false, // To control the countdown
    };
    this.interval = null; // Interval reference
    this.handleStartGame = this.handleStartGame.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
  }

  database = getDatabase(app);

   componentDidMount(){
    const db = getDatabase();
    const starCountRef = ref(db, 'scores/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      this.setState({standing:Object.keys(data).map((key) => ({
        id: key,
        ...data[key]
      }))})
      // updateStarCount(postElement, data);
    })
   }
  handleStartGame = () => {
    this.setState({ isStarted: !this.state.isStarted });
  };

  handleAnswer = (point) => {
    this.setState(
      {
        isClicked: true,
        isDisabled: true,
        scoredPoint: (this.state.scoredPoint += point),
      },
      () => {
        if (this.state.questions.length != this.state.questionCount + 1) {
          this.handleNext();
        }
      }
    );
  };

  handleSubmit = () => {
    console.log("STATES:: ", this.state);
  };

  handleNext = () => {
    // e.preventDefault()
    console.log("STATES 1:: ", this.state);
    this.setState({
      questionCount: this.state.questionCount + 1,
      isClicked: false,
      isDisabled: false,
    });
    console.log("STATES:-AFTER: ", this.state);
  };

  handlePrevious = (e) => {
    e.preventDefault();
    console.log("STATES:: ", this.state);
    this.setState({ questionCount: this.state.questionCount - 1 });
  };

  questions = [
    {
      question: "who is boti",
      answer: "A",
      options: [
        { point: 1, option: "A", isAnswer: true },
        { point: 0, option: "B", isAnswer: false },
        { point: 0, option: "C", isAnswer: false },
        { point: 0, option: "D", isAnswer: false },
      ],
    },
    {
      question: "WHERE is boti",
      answer: "D",
      options: [
        { point: 0, option: "A", isAnswer: false },
        { point: 0, option: "B", isAnswer: false },
        { point: 0, option: "C", isAnswer: false },
        { point: 1, option: "D", isAnswer: true },
      ],
    },
    {
      question: "HOW is boti",
      answer: "C",
      options: [
        { point: 0, option: "A", isAnswer: false },
        { point: 0, option: "B", isAnswer: false },
        { point: 1, option: "C", isAnswer: true },
        { point: 0, option: "D", isAnswer: false },
      ],
    },
    {
      question: "WHY is boti",
      answer: "C",
      options: [
        { point: 0, option: "A", isAnswer: false },
        { point: 0, option: "B", isAnswer: false },
        { point: 1, option: "C", isAnswer: true },
        { point: 0, option: "D", isAnswer: false },
      ],
    },
    {
      question: "WHAT is boti",
      answer: "B",
      options: [
        { point: 0, option: "A", isAnswer: false },
        { point: 1, option: "B", isAnswer: true },
        { point: 0, option: "C", isAnswer: false },
        { point: 0, option: "D", isAnswer: false },
      ],
    },
  ];

  // handleStartGame = () => {
  //    this.setState({ start: !this.state.start });
  // };

  register = (e) => {
    e.preventDefault();
    console.log("STATES:: ", this.state);
    this.setState({ isRegistered: true }, () => {
      this.startCountdown();
    });
  };

  joinGame = (e) => {
    e.preventDefault();
    console.log("STATES:: ", this.state);
    this.setState({ joinedGame: true });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  startCountdown = () => {
    this.setState({
      timer: this.state.countDown, // Set the timer to 60 seconds
      counting: true, // Start the countdown
    });

    this.interval = setInterval(this.updateTimer, 1000);
  };

  updateTimer = () => {
    if (this.state.timer > 0) {
      this.setState((prevState) => ({
        timer: prevState.timer - 1,
      }));
    } else {
      console.log("SUBMIT RESULT");
      clearInterval(this.interval);
      this.setState({
        counting: false, // Countdown finished, set counting to false
      });
    }
  };

  render() {
    const sortedArray = this.state.standing.slice().sort((a, b) => b.score - a.score);

    return (
      <div id="section-5" className="page-content">
        <Header/>
        <div className="container about-us p-2 text-light">
          <h3 className="headline" >Score Board</h3>
            <table
             className="container"
            >
              <thead>
                {/* <tr> */}

                <td>S/N</td>
                <td>Name</td>
                {/* <td>Email</td> */}
                <td>Phone</td>
                <td>Score</td>
                {/* </tr> */}
              </thead>
        {sortedArray.map((data, index) => (
              <tbody key={index}>
                
                <tr>
                  
                  <td> {index + 1}</td>
                <td>{data.name.split(" ")[0]}</td>
                {/* <td>{data.email}</td> */}
                <td>{data.phone}</td>
                <td>{data.score}</td>
                </tr>
              </tbody>
            ))}
            </table>
        </div>
        <Footer/>
      </div>
    );
  }
}
