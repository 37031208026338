import React from "react";
import Email from "./Verification/email";
import Phone from "./Verification/phone";
import Success from "./Verification/success";
import Failure from "./Verification/failed";

import { useParams } from "react-router";

function App() {
  let { stage, token } = useParams();
  let result = null;

  if (stage === "email") {
    result = <Email token={token} />;
  } else if (stage === "success") {
    result = <Success />;
  } else if (stage === "failed") {
    result = <Failure />;
  }

  return <div className="container"> {result}</div>;
}

export default App;
