import React from "react";
import Email from "./Verification/email";
import Phone from "./Verification/phone";
import Success from "./Verification/success";
import Failure from "./Verification/failed";

import { useParams } from "react-router";
import { useEffect } from "react";

function App() {
  let { referral_code } = useParams();
  useEffect(() => {
    if (referral_code !== undefined) {
      window.location.href =
        "https://app.kochure.com/signup?referral_code=" + referral_code;
    } else {
      window.location.href = "https://app.kochure.com/signup";
    }
  });

  return <div className="container"></div>;
}

export default App;
