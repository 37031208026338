import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import Icon from "../../components/shared/Icon";
import VerticalBlogCard from "../../components/shared/Vertical-Blog-Card";
import { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var";
import { useParams } from "react-router";
import moment from "moment";
import { Helmet } from 'react-helmet'

export default function App() {
  let { url } = useParams();

  return (
    <div className="container">
      {" "}
      <BlogPost url={url} />
    </div>
  );
}

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      posts: [],
      post: {},
    };
  }
  componentDidMount() {
    let url = this.props.url;

    axios
      .get(ConstantVar.API_BASE_URL + "/general/blog/post/", {
        params: {
          url,
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        this.setState({
          posts: response.data.data.posts,
          post: response.data.data.post,
          isLoading: false,
        });
      });
  }

  onLoadContent = () => {
    var iframe = window.document.getElementById("blogIframe");
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
  };

  render() {
    return (
      <>
        <Helmet>
        {/* <html lang="en" />   */}
        <link rel="canonical" href={`https://kochure.com/blog/${this.state.post.title}`} />

        <meta 
          name="keywords"
          content="Bitcoin trading platform for newbies"
        />

      
      </Helmet>
        <Header />
        <div className="page-content">
          <div class="container">
            {!this.state.isLoading ? (
              <>
                <div class="blog-post-title col-lg-7 mx-auto">
                  {this.state.post.title}
                </div>
                <div class="text-center my-5 blog-nav">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/blog">Blog</a>
                    </li>
                    <li>
                      <a class="page-active">{this.state.post.title}</a>
                    </li>
                  </ul>
                </div>
                <div class="blog-post-timestamp text-center">
                  {" "}
                  {moment(this.state.post.created_at).format("MMMM DD, YYYY")}
                </div>
                <div class="mt-5 blog-content ">
                  <div class="text-center">
                    <img
                      className="img-fluid mx-auto rounded "
                      alt="Responsive image"
                      src={
                        `${ConstantVar.API_BASE_URL}/static/blog/` +
                        this.state.post._id +
                        "/featured.png"
                      }
                    />
                  </div>
                  {/* <div
                    class="col-12 col-lg-7 mx-auto mt-5"
                    dangerouslySetInnerHTML={{ __html: this.state.post.content }}
                  ></div> */}

                  {/* <div class="col-12 col-lg-7 mx-auto mt-5"> */}
                    <iframe
                      width="100%"
                      id="blogIframe"
                      srcDoc={this.state.post.content}
                      onLoad={this.onLoadContent}
                      style={{
                        // overflow: "hidden",
                      }}
                    ></iframe>
                  {/* </div> */}
                  

                  <div class="my-5 text-center">
                    <div class="mx-2 d-inline-block">
                      <Icon
                        height="30px"
                        width="30px"
                        icon="/assets/images/icons/twitter.svg"
                      />
                    </div>
                    <div class="mx-2 d-inline-block">
                      <Icon
                        height="30px"
                        width="30px"
                        icon="/assets/images/icons/facebook.svg"
                      />
                    </div>
                    <div class="mx-2 d-inline-block">
                      <Icon
                        height="30px"
                        width="30px"
                        icon="/assets/images/icons/instagram.svg"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-10 recent-posts mx-auto mt-5">
                    <div class="heading">Recent posts</div>
                    <div class="row">
                      {this.state.posts.length && (
                        <>
                          {this.state.posts.map((post, index) => {
                            return (
                              <div className="col-12 col-md-4 col-lg-3">
                                <VerticalBlogCard post={post} />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center p-5 m-5">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
