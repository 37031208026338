import { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Icon from "./Icon";
import NumberFormat from "../shared/Number-Format";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      init: true,
      showMobileMenu: false,
      showMobileSubMenu: false,
      appData: null,
      btc: null,
      isLoaded: false,
    };
    this.node = null;
  }

  componentDidMount() {
    this._isMounted = true;
    console.log(this._isMounted);
  }

  toggleNav = () => {
    if (this._isMounted) this.setState({ showMobileMenu: !this.state.showMobileMenu });
  };

  toogleMobileSubMenu = () => {
    if (this._isMounted)
      this.setState({ showMobileSubMenu: !this.state.showMobileSubMenu });
  };

  handleClick = () => {
    if (!this.state.showMobileMenu) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    if (this._isMounted)
      this.setState({ init: this.state.showMobileMenu ? false : true });
    this.toggleNav();
  };

  handleOutsideClick = (e) => {
    if (this.node != null && !this.state.init && !this.node.contains(e.target))
      this.handleClick();
    if (this._isMounted)
      this.setState({ init: this.state.showMobileMenu ? false : true });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  gotoStore() {
    var isMobile = {
      Android: function () {
        return window.navigator.userAgent.match(/Android/i);
      },
      iOS: function () {
        return window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
    };
    if (isMobile.iOS()) {
      window.location.href = "https://apps.apple.com/ng/app/kochure/id1595574962";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.kochure.kochureApp";
    }
  }

  render() {
    const btcNairaVal = parseFloat(localStorage.getItem("btcNairaVal"));
    const btc24hra = localStorage.getItem("btc24hrs");

    return (
      <div id="home-header">
        <div className="container">
          <div className="d-flex flex-row mt-2 px-2">
            <div
              className="d-flex flex-wrap align-content-center"
              style={{
                marginRight: "13px",
              }}
            >
              <a
                className="d-block d-lg-none"
                onClick={this.handleClick}
                id="home-header-mobile-nav-link"
              >
                <img alt="" src="/assets/images/icons/ellipse.svg" />
              </a>
            </div>

            <div className="d-flex flex-wrap align-content-center">
              <div className="logo">
                <Link to="/">
                  <img alt="" src={Logo} />
                </Link>
              </div>
            </div>

            <div
              className="d-none d-lg-flex flex-wrap align-content-center"
              id="desktop-menu"
            >
              <ul>
                <li className="dropdown">
                  <a>
                    Company{" "}
                    <Icon
                      height="16px"
                      width="16px"
                      icon="/assets/images/icons/arrow-down.svg"
                    ></Icon>
                  </a>
                  <div className="dropdown-content">
                    <Link to="/about-us">About Us</Link>
                    <Link to="">Careers</Link>
                  </div>
                </li>
                <li>
                  <a href="/#market">Market</a>
                </li>
                <li>
                  <a href="/blog" target="_blank">Blog</a>
                </li>
                <li className="live-rate">
                  1 BTC = <NumberFormat number={btcNairaVal || 0} noOfDecimal={0} />
                  NGN{" "}
                  <span style={{ color: btc24hra < 0 && "red" }}>
                    {btc24hra}
                    <Icon
                      height="16px"
                      width="16px"
                      icon={
                        btc24hra > 0
                          ? "/assets/images/icons/arrow-up-green.svg"
                          : "/assets/images/icons/arrow-down-red.svg"
                      }
                    />
                  </span>
                </li>
              </ul>
            </div>

            <div className=" flex-grow-1 d-flex flex-row align-items-center justify-content-end">

              <a href="https://app.kochure.com/" className="p-3  d-none d-lg-block" style={{
                color: "#1d38e4",
                fontSize: "16px",
                fontWeight: "bold"
              }}>Login</a>
              <a
                href="https://app.kochure.com/signup"
                className=" primary-link-btn d-none d-lg-block"
                id="home-header-btn-link"
              >
                Create Account
              </a>
              <a
                href="https://app.kochure.com/signup"
                className=" primary-link-btn d-block d-lg-none"
                id="home-header-btn-link-sm"
              >
                Create Account
              </a>
            </div>
          </div>
        </div>

        {this.state.showMobileMenu && (
          <div id="myNav" className="overlay-menu">
            <div id="home-header">
              <div className="container">
                <div className="d-flex flex-row">
                  <div className="d-flex flex-wrap align-content-center">
                    <div className="logo">
                      <Link to="/">
                        <img src="/assets/images/logo.svg" />
                      </Link>
                    </div>
                  </div>
                  <div className="flex-grow-1 pe-2 d-flex align-items-center justify-content-end ">
                    <span onClick={this.handleClick}>
                      <Icon
                        height="29px"
                        width="29px"
                        icon="/assets/images/icons/close.svg"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="overlay-menu-content"
              ref={(node) => {
                this.node = node;
              }}
            >
        
              <Link to="https://app.kochure.com/">Login</Link>

              <div onClick={this.toogleMobileSubMenu} className="dropdown menu">
                Company
                <Icon
                  height="16px"
                  width="16px"
                  icon="/assets/images/icons/arrow-down.svg"
                ></Icon>
                {this.state.showMobileSubMenu && (
                  <div className="dropdown-content">
                    <Link to="/about-us">About Us</Link>
                    <Link to="/">Careers</Link>
                  </div>
                )}
              </div>
              <span onClick={this.handleClick}>
                <a href="/#market">Market</a>
              </span>

              <Link to="/blog">Blog</Link>
              <a className="live-rate mt-5 mx-4">
                1 BTC = <NumberFormat number={btcNairaVal || 0} noOfDecimal={0} />
                <span style={{ color: btc24hra < 0 && "red" }}>
                  {btc24hra}

                  <Icon
                    height="16px"
                    width="16px"
                    icon={
                      btc24hra > 0
                        ? "/assets/images/icons/arrow-up-green.svg"
                        : "/assets/images/icons/arrow-down-red.svg"
                    }
                  />
                </span>
              </a>
              <span onClick={this.gotoStore}>
                {" "}
                <a to="#" className="mt-5" id="home-header-btn-link">
                  Get Kochure
                </a>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
